<template>
  <EbzFrame
    :class="[
      'ebz-frame-thumbnail',
      `ebz-frame-thumbnail--variant-${variant}`,
      {
        'ebz-frame-thumbnail--loading': isLoading,
        'ebz-frame-thumbnail--disabled': disabled,
        'ebz-focusable': !disabled,
      },
    ]"
    :variant="variant"
    :tabindex="disabled ? undefined : '0'"
    role="button"
    ref="frameRef"
    @keydown.enter="onClick"
    @click="onClick"
  >
    <Transition name="ebz-transition-fade">
      <EbzImage
        v-if="!isLoading"
        class="w-100% h-100% pointer-events-none"
        :src="image"
        :loading-src="loadingImage"
        :alt="alt || title"
        fit="cover"
        fit-position="center"
        loading="lazy"
      ></EbzImage>

      <div v-else class="ebz-frame-thumbnail__loading">
        <slot name="loading">
          <EbzLoading size="4rem"></EbzLoading>
        </slot>
      </div>
    </Transition>

    <div v-if="title" class="ebz-frame-thumbnail__title">
      <span class="text-center text-subtitle-1 uppercase m-0 px-2 max-w-70%">{{
        title
      }}</span>
    </div>
  </EbzFrame>
</template>

<script lang="ts">
export const makeCaseProps = propsFactory({
  image: {
    type: String,
    required: true,
  },
  loadingImage: String,
  title: String,
  alt: String,
  disabled: Boolean,
  ...makeEbzFrameProps({
    variant: "",
  }),
});
</script>

<script lang="ts" setup>
import { propsFactory } from "@/common/props";
import {
  EbzFrame,
  makeEbzFrameProps,
  VARIANT_NAMES,
} from "@/components/EbzFrame";
import { EbzImage } from "@/components/EbzImage";
import { useImage } from "@vueuse/core";
import { computed, ref } from "vue";
import { EbzLoading } from "../EbzLoading";

const props = defineProps(makeCaseProps());
const emit = defineEmits(["click"]);

const frameRef = ref<InstanceType<typeof EbzFrame>>();

const isFrameLoading = computed(() => frameRef.value?.isLoading);
const { isLoading: isImageLoading } = useImage(
  computed(() => ({
    src: props.image,
  }))
);
const isLoading = computed(() => isFrameLoading.value || isImageLoading.value);

const onClick = (e: PointerEvent | KeyboardEvent) => {
  if (!props.disabled) {
    e.preventDefault();
    emit("click");
  }
};

const variant = computed(() => {
  let variantName = props.variant;

  if (!variantName || !VARIANT_NAMES.includes(variantName)) {
    variantName =
      VARIANT_NAMES[1 + ~~(Math.random() * (VARIANT_NAMES.length - 1))];
  }

  return variantName;
});
</script>

<style lang="scss">
$transition-duration: 1s;
$transition-timing-function: cubic-bezier(0.2, 0.05, 0, 1);
$transition-delay: 0.1s;

.ebz-frame-thumbnail {
  &,
  &__title {
    transition: opacity #{$transition-duration} #{$transition-timing-function};
  }

  &__title {
    @apply absolute text-white z-2 top-0 left-0 flex items-center justify-center;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.75);
    opacity: 0;
    will-change: opacity;
    transition-delay: #{$transition-delay};

    h6 {
      transition-property: transform, opacity;
      transition-duration: #{$transition-duration};
      transition-timing-function: #{$transition-timing-function};
      transform: translate3d(0, 25%, 0);
      opacity: 0;
      will-change: transform, opacity;
    }
  }

  .ebz-image {
    transition-property: transform, filter;
    transition-duration: #{$transition-duration};
    transition-timing-function: #{$transition-timing-function};
    transform-origin: center;
    will-change: transform, filter;
  }

  &:not(.ebz-frame-thumbnail--disabled) {
    cursor: pointer;

    &:hover,
    &:focus {
      .ebz-frame-thumbnail {
        &__title {
          opacity: 1;
          transition-delay: 0s;

          h6 {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            transition-delay: #{$transition-delay};
          }
        }
      }

      .ebz-image {
        transform: scale(1.25);
      }
    }
  }

  &--disabled {
    opacity: 0.75;

    .ebz-image {
      filter: grayscale(100%);
    }
  }
}
</style>
