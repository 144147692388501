<template>
  <div
    :class="[
      'ebz-frame',
      {
        'ebz-frame--loading': isLoading,
      },
    ]"
    :style="{
      backgroundImage: variant && `url(${variant?.frame})`,
      width: coercePixel(width),
      aspectRatio: ratio,
    }"
    ref="elementRef"
  >
    <div
      class="ebz-frame__content"
      :style="{
        maskImage: variant && `url(${variant?.mask})`,
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
const normalize = <T>(path: string, url: T) => {
  const variant = path
    .slice(path.lastIndexOf("/") + 1)
    .replace(/(^\w+_(\w+).png)/, "$2");
  return [variant, url] as const;
};

const transformGlob = createGlobTransformer(normalize);

const framesMetadata = transformGlob<
  Required<Pick<Metadata, "width" | "height">>
>(
  import.meta.glob<Metadata>("@/assets/img/frames/frame_[a-z].png", {
    import: "default",
    eager: true,
    query: {
      format: "webp",
      metadata: "width;height",
    },
  })
);

const frames = transformGlob<string>(
  import.meta.glob<string>("@/assets/img/frames/frame_[a-z].png", {
    import: "default",
    eager: true,
    query: {
      format: "webp",
      quality: 85,
    },
  })
);

const masks = transformGlob<string>(
  import.meta.glob<string>("@/assets/img/frames/mask_[a-z].png", {
    import: "default",
    eager: true,
    query: {
      format: "webp",
      quality: 85,
    },
  })
);

export const VARIANTS = Object.fromEntries(
  Object.entries(frames).map(([variant, frameUrl]) => {
    const maskUrl = masks[variant];
    const metadata = framesMetadata[variant];

    return [
      variant,
      {
        metadata,
        frame: frameUrl,
        mask: maskUrl,
      },
    ];
  })
);

export const VARIANT_NAMES = Object.keys(VARIANTS);

export type EbzFrameVariant = (typeof VARIANTS)[keyof typeof VARIANTS];

export const makeEbzFrameProps = propsFactory(
  {
    variant: {
      type: String,
      required: true,
      validator: (variant: string) => variant in VARIANTS,
    },
  },
  "ebz-frame"
);
</script>

<script lang="ts" setup>
import { coercePixel } from "@/common/coercion";
import { createGlobTransformer } from "@/common/import";
import { propsFactory } from "@/common/props";
import { useImage } from "@vueuse/core";
import type { Metadata } from "sharp";
import { computed, ref } from "vue";

const props = defineProps(makeEbzFrameProps());

const variant = computed(() => VARIANTS[props.variant]);

const { isLoading: frameLoading } = useImage(() => ({
  src: variant.value?.frame ?? "",
}));

const { isLoading: maskLoading } = useImage(() => ({
  src: variant.value?.mask ?? "",
}));

const isLoading = computed(() => frameLoading.value || maskLoading.value);

const elementRef = ref<HTMLElement>();

const ratio = computed(() => {
  if (!variant.value) {
    return 0.5;
  }

  const { metadata } = variant.value;
  return metadata.width / metadata.height;
});

const width = computed(() => {
  if (!variant.value) {
    return;
  }

  const { metadata } = variant.value;
  return metadata.width;
});

defineExpose({
  isLoading: frameLoading,
});
</script>

<style lang="scss">
.ebz-frame {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 100%;

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    overflow: hidden;
    contain: strict;
  }
}
</style>
