<template>
  <EbzField
    v-bind="fieldProps"
    v-on="fieldHandlers"
    v-slot="{ props, dimensions }"
  >
    <div
      class="ebz-textarea"
      :class="{
        'ebz-textarea--no-resize': noResize,
      }"
      :style="{
        '--ebz-textarea-height': dimensions.height,
        '--ebz-textarea-width': dimensions.width,
        '--ebz-textarea-min-height':
          dimensions.minHeight || `${minRowsHeight}px`,
        '--ebz-textarea-max-height': maxRowsHeight
          ? `${maxRowsHeight}px`
          : dimensions.maxHeight,
        '--ebz-textarea-min-width': dimensions.minWidth,
        '--ebz-textarea-max-width': dimensions.maxWidth,
      }"
    >
      <textarea
        v-bind="mergeProps(props, inputProps ?? {})"
        :rows="rows"
        :placeholder="placeholder"
        ref="textarea"
      ></textarea>
    </div>
  </EbzField>
</template>

<script lang="ts">
export const makeEbzTextInputProps = propsFactory(
  {
    placeholder: String,
    inputProps: Object,
    noResize: Boolean,
    rows: {
      type: [Number, String],
      default: 5,
      validator: (v: any) => !isNaN(parseFloat(v)),
    },
    maxRows: {
      type: [Number, String],
      validator: (v: any) => !isNaN(parseFloat(v)),
    },
    ...makeEbzFieldProps(),
  },
  "ebz-textarea"
);
</script>

<script lang="ts" setup>
import { propsFactory } from "@/common/props";
import { computed, mergeProps, ref } from "vue";
import { EbzField, makeEbzFieldProps } from "../EbzField";
import { useEbzField } from "../EbzField/useEbzField";

const props = defineProps(makeEbzTextInputProps());
const emit = defineEmits(["update:modelValue", "input", "blur"]);
const { fieldProps, fieldHandlers } = useEbzField(props, emit);
const textarea = ref<HTMLTextAreaElement>();

const computedStyle = computed(() => {
  if (!textarea.value) return;

  const style = getComputedStyle(textarea.value);
  return style;
});

const lineHeight = computed(() => {
  if (!computedStyle.value) return;
  return parseFloat(computedStyle.value.lineHeight);
});

const minRowsHeight = computed(() => {
  if (!computedStyle.value) return;
  const paddingTop = parseFloat(computedStyle.value.paddingTop);
  const paddingBottom = parseFloat(computedStyle.value.paddingBottom);
  const borderTopWidth = parseFloat(computedStyle.value.borderTopWidth);
  const borderBottomWidth = parseFloat(computedStyle.value.borderBottomWidth);
  return (
    paddingTop +
    paddingBottom +
    borderTopWidth +
    borderBottomWidth +
    lineHeight.value!
  );
});

const maxRowsHeight = computed(() => {
  if (!props.maxRows || !computedStyle.value) return;
  const maxRows = parseFloat(`${props.maxRows}`);
  return minRowsHeight.value! + lineHeight.value! * (maxRows - 1);
});
</script>

<style lang="scss">
:root {
  --ebz-textarea-width: 100%;
  --ebz-textarea-height: 100%;
  --ebz-textarea-min-width: 0;
  --ebz-textarea-min-height: 40px;
  --ebz-textarea-max-width: 100%;
  --ebz-textarea-max-height: 100%;
}

.ebz-textarea {
  width: 100%;

  &--no-resize {
    .ebz-field__input {
      resize: none;
    }
  }

  .ebz-field__input {
    width: 100%;
    height: var(--ebz-textarea-height);
    min-height: var(--ebz-textarea-min-height);
    max-height: var(--ebz-textarea-max-height);
    min-width: var(--ebz-textarea-min-width);
    max-width: var(--ebz-textarea-max-width);
  }
}
</style>
