<template>
  <div
    class="ebz-field"
    :class="{
      'ebz-field--invalid': !!errorMessage,
      'ebz-field--dirty': !!value,
      'ebz-field--disabled': disabled,
    }"
  >
    <div class="ebz-field__wrapper">
      <div class="ebz-field__control" :role="role">
        <slot
          :props="{
            onInput: onInput,
            onBlur: onBlur,
            value: value,
            name: name,
            id: fieldId,
            'aria-describedby': messagesId,
            class: 'ebz-field__input',
            disabled: disabled,
          }"
          :dimensions="dimensionStyles"
          :errorMessage="errorMessage"
          v-bind="slotProps"
        ></slot>
      </div>

      <span
        class="ebz-field__error"
        :id="messagesId"
        role="alert"
        aria-live="polite"
      >
        <slot name="error" :errorMessage="errorMessage">
          <Transition name="ebz-field__error-transition" mode="out-in">
            <span
              v-if="errorMessage"
              class="ebz-field__error__message text-selective-yellow select-text"
              :key="errorMessage"
            >
              {{ errorMessage }}
            </span>
          </Transition>
        </slot>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
export const makeEbzFieldProps = propsFactory(
  {
    modelValue: String,
    id: String,
    disabled: Boolean,
    name: {
      type: String,
      required: true,
    },
    rules: {
      type: [String, Object] as PropType<RuleExpression<unknown>>,
    },
    role: {
      type: String,
      default: "textbox",
    },
    keepValueOnUnmount: null as unknown as PropType<boolean | undefined>,
    label: String,
    ...makeDimensionProps(),
  },
  "ebz-field"
);
</script>

<script lang="ts" setup>
import { propsFactory } from "@/common/props";
import { getUid } from "@/common/uid";
import { makeDimensionProps, useDimension } from "@/composables/useDimension";
import { useField, type RuleExpression } from "vee-validate";
import { computed, reactive, toRef, type PropType } from "vue";

const props = defineProps(makeEbzFieldProps());
const emit = defineEmits(["update:modelValue", "input", "blur"]);
const name = toRef(props, "name");
const rules = toRef(props, "rules");
const keepValueOnUnmount = toRef(props, "keepValueOnUnmount");
const label = toRef(props, "label");
const { dimensionStyles } = useDimension(props);

const { value, handleBlur, handleChange, errorMessage, ...fieldProps } =
  useField(name, rules, {
    initialValue: props.modelValue,
    keepValueOnUnmount,
    label,
  });

const slotProps = reactive(fieldProps);
const uid = getUid();
const fieldId = computed(() => props.id || `ebz-input-${uid}`);
const messagesId = computed(() => `${fieldId.value}-messages`);

const onInput = (e: unknown, shouldValidate?: boolean | undefined) => {
  emit("input", e, shouldValidate);
  handleChange(e, shouldValidate);
};

const onBlur = (e: Event) => {
  emit("blur", e);
  handleBlur(e);
};
</script>

<style lang="scss">
.ebz-field {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0.5rem 0;
  width: 100%;

  &--disabled {
    .ebz-field__input {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &__input {
    flex: 1;
    color: inherit;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    background-color: transparent;
    border-style: none;
    border-radius: 0;
    min-width: 0;
    padding: 0.25rem 0.5rem;
    transition: box-shadow 0.2s ease;
    min-height: 40px;

    &:focus {
      outline: none !important;
      box-shadow: 0 0 0 1px currentColor;
    }
  }

  &__wrapper {
    width: 100%;
    display: grid;
    position: relative;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto auto;
    contain: layout;
  }

  &__error {
    display: block;
    position: relative;
    width: calc(100% + 2px);
    margin-left: -1px;
    font-size: 0.825rem;
    line-height: 1.2;
    min-height: calc(1.2em + 4px);

    &__message {
      display: block;
      width: 100%;
      padding: 2px 6px;
    }

    &-transition {
      &-enter-active,
      &-leave-active {
        transition-property: opacity, transform;
        transition-duration: 0.15s;
        transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
      }

      &-enter-from,
      &-leave-to {
        opacity: 0;
        transform: translate3d(0, -0.5rem, 0);
      }

      &-enter-to,
      &-leave-from {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }
}
</style>
