/**
 * Creates a transformer function to normalize glob imports.
 */
export function createGlobTransformer<K extends string | number | symbol, V>(
  cb: <T>(path: string, value: T) => [K, V] | readonly [K, V]
) {
  return <T extends V>(modules: Record<string, any>): Record<K, T> => {
    const res = Object.create(null);
    Object.keys(modules).forEach((key) => {
      const [k, v] = cb(key, modules[key]);
      res[k] = v;
    });
    return res;
  };
}
