import {
  h,
  isVNode,
  Comment,
  type Component,
  type VNode,
  type VNodeChild,
  type VNodeProps,
  type DefineComponent,
} from "vue";

export function vnodeProps(
  vnode: VNode,
  override?: VNodeProps & { [key: string]: any }
): VNodeProps & { [key: string]: any } {
  const props: VNodeProps & { [key: string]: any } = { ...vnode.props };

  if (vnode.ref) {
    props.ref = vnode.ref as any;
  }

  if (override) {
    Object.assign(props, override);
  }

  return props;
}

export function renderVNode(
  node: VNode,
  props?: VNodeProps & { [k: string]: any },
  children?: any[]
) {
  if (typeof node.type === "string") {
    return h(node.type, vnodeProps(node, props), children);
  }
  if (typeof node.type === "symbol") {
    return children;
  }

  // Raw slots
  if (
    children &&
    children.length === 1 &&
    !isVNode(children[0]) &&
    typeof children[0] === "object"
  ) {
    return h(node.type as Component, vnodeProps(node, props), children[0]);
  }

  return h(node.type as Component, vnodeProps(node, props), () => children);
}

export function parseVNode(
  node: VNodeChild,
  cb: (
    node: VNode | string | number | boolean | null | undefined | void,
    depth: number,
    children?: any[]
  ) => any,
  depth: number
): VNodeChild | { [key: string]: any } | undefined {
  if (Array.isArray(node)) {
    return node.map((child) => parseVNode(child, cb, depth));
  }

  if (isVNode(node)) {
    if (node.type === Comment) {
      return node;
    }

    const children = ([] as (VNodeChild | { [key: string]: any })[]).concat(
      parseVNode(node.children as VNodeChild, cb, depth + 1)
    );

    const result = cb(node, depth, children);

    if (result === false) {
      return;
    } else if (result && result !== true) {
      return result;
    }

    return renderVNode(node, {}, children);
  }

  // Raw slots
  if (node && typeof node === "object") {
    const slots: Record<string, any> = {};

    for (const [key, slotFn] of Object.entries(node)) {
      if (typeof slotFn === "function") {
        slots[key] = (...args: any[]) => {
          const slotContent = slotFn(...args);
          const children = parseVNode(slotContent, cb, depth + 1);
          return children;
        };
      } else {
        slots[key] = slotFn;
      }
    }

    return slots;
  }

  const result = cb(node, depth);

  if (result === false) {
    return;
  } else if (result && result !== true) {
    return result;
  }

  return node;
}

export function createVNodeParser(
  cb: (
    node: VNode | string | number | boolean | null | undefined | void,
    depth: number,
    children?: any[]
  ) => any
) {
  return (node: VNodeChild) => parseVNode(node, cb, 0);
}

export function isComponent(
  component: any
): component is Component | DefineComponent {
  return (
    typeof component === "object" ||
    "displayName" in component ||
    "props" in component ||
    "__vccOpts" in component
  );
}
