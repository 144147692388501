<template>
  <section
    class="ebz-about-page relative w-100vw h-auto mt-25vh z-5 text-white"
    ref="elementRef"
  >
    <EbzRippedPaper
      class="z-0 absolute left-0 w-100% overflow-hidden top-70% h-30% md:top-60% md:h-40% lg:h-50% lg:top-50%"
      background="#F8F8F8"
      v-once
    >
    </EbzRippedPaper>

    <div class="relative z-1">
      <!-- Text -->
      <div
        class="ebz-about-page__heading-section px-8 py-8 flex items-end justify-center whitespace-pre-line text-center lt-sm:whitespace-normal xl:py-10"
      >
        <Heading :delay="0.25" :play="play" ref="headingRef"></Heading>
      </div>

      <!-- Paper area -->
      <div
        class="ebz-about-page__paper-section px-8 py-7 flex items-start justify-center xl:py-10"
        v-once
      >
        <div class="ebz-about-page__paper" ref="paperRef">
          <EbzPieceOfPaper variant="c" interactive>
            <div class="relative w-10em text-center">
              <h2
                class="ebz-about-page__paper__title uppercase !text-size-inherit mt-[-0.4em]"
              >
                {{ t("about_page.paper_title") }}
              </h2>

              <EbzSprite
                class="ebz-about-page__paper__desc !absolute w-100% h-100% left-0 top-55%"
                :src="gameBasedSrc"
                :data="gameBasedData"
                :scale="1.1"
                :delay="1.25"
                :speed="0.8"
                :play="play"
                animation="GAME_BASED"
              ></EbzSprite>
            </div>

            <EbzImage
              class="ebz-about-page__paper__tape absolute top-[-0.2em] left-[-0.2em] w-2.5em h-auto"
              :src="tapeSrc"
              :metadata="tapeMeta"
              :loading-src="tapeSrcLQ"
              preload
            ></EbzImage>
          </EbzPieceOfPaper>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import gameBasedSrc from "@/assets/img/pg-1/game_based.webp?format=webp&imagetools";
import gameBasedData from "@/assets/img/pg-1/game_based.json";
import tapeSrc from "@/assets/img/pg-1/tape.png?format=webp&width=200&imagetools";
import tapeSrcLQ from "@/assets/img/pg-1/tape.png?format=webp&width=32&imagetools";
import tapeMeta from "@/assets/img/pg-1/tape.png?meta&imagetools";
import { EbzRippedPaper } from "@/components/EbzRippedPaper";
import { onMounted, ref } from "vue";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useGsap } from "@/composables/useGsap";
import { EbzSprite } from "@/components/EbzSprite";
import { EbzImage } from "@/components/EbzImage";
import { useI18n } from "vue-i18n";
import { EbzPieceOfPaper } from "@/components/EbzPieceOfPaper";
import { useSpriteLoader } from "@/composables/useSpriteLoader";
import Heading from "./Heading.vue";

await useSpriteLoader(gameBasedSrc);

const { t } = useI18n();

const gsap = useGsap();
gsap.registerPlugin(ScrollTrigger);

const elementRef = ref<HTMLElement>();
const headingRef = ref<InstanceType<typeof Heading>>();
const paperRef = ref<HTMLElement>();
const play = ref(false);

onMounted(() => {
  const el = elementRef.value!;
  const headingEl = headingRef.value!.$el as HTMLElement;
  const paperEl = paperRef.value!;

  gsap.set(headingEl, {
    opacity: 0,
    yPercent: 100,
  });
  gsap.set(paperEl, {
    opacity: 0,
    scale: 1.15,
  });

  gsap
    .timeline({
      scrollTrigger: {
        trigger: el,
        start: "top 65%",
        end: "top 40%",
        once: true,
        scrub: 0.5,
        onKill() {
          play.value = true;

          gsap.to(paperEl, {
            delay: 1,
            opacity: 1,
            scale: 1,
            duration: 0.8,
            ease: "power3.out",
          });
        },
      } as ScrollTrigger.Vars,
    })
    .to(headingEl, {
      opacity: 1,
      yPercent: 0,
    });
});
</script>

<style lang="scss">
@use "sass:string";
@use "@/styles/tools";

.ebz-about-page {
  &__heading {
    @include tools.fluid-size(
      font-size,
      1rem,
      string.unquote("0.75rem + 1.75vmin"),
      2rem,
      true
    );
  }

  &__paper {
    @include tools.fluid-size(
      font-size,
      1.5rem,
      string.unquote("1rem + 4.5vmin"),
      3.5rem,
      true
    );
  }
}
</style>

<i18n lang="yaml">
pt-BR:
  about_page:
    paper_title: ISSO É SER
</i18n>
