<template>
  <section
    class="ebz-contact-page relative w-100% h-auto flex flex-col justify-between color-white"
  >
    <!-- Go Up Button -->
    <div class="flex justify-end sticky top-0 z-5">
      <EbzArrowUp class="mt-8 mr-8" ref="arrowUpRef"></EbzArrowUp>
    </div>

    <!-- Ripped Paper -->
    <div
      class="absolute z-1 top-65% left-0 w-100% h-35% overflow-hidden"
      v-once
    >
      <EbzRippedPaper
        class="relative z-1 w-100% h-100% overflow-hidden"
        background="#EFF1F3"
        variant="b"
      >
      </EbzRippedPaper>

      <!-- Tapes -->
      <EbzSprite
        class="!z-2 w-10vmin h-20vmin !absolute left-10vw top-5% !lt-md:hidden"
        :src="tapeSpriteSrc"
        :data="tapeSpriteData"
        frame="tape_l.png"
      ></EbzSprite>
      <EbzSprite
        class="!z-2 w-10vmin h-20vmin !absolute right-10vw top-5% !lt-md:hidden"
        :src="tapeSpriteSrc"
        :data="tapeSpriteData"
        frame="tape_r.png"
      ></EbzSprite>
    </div>

    <!-- Doodles -->
    <div
      class="absolute z-0 left-0 bottom-0 w-100% h-45% max-h-100% will-change-clip-path overflow-hidden contain-strict"
      ref="doodlesRef"
      v-once
    >
      <EbzImage
        class="w-100% h-100%"
        :src="doodlesSrc"
        :srcset="doodlesSrcset"
        :loading-src="doodlesSrcLQ"
        :metadata="doodlesMeta"
        fit="contain"
        fit-position="top"
        repeat="x"
        repeat-flip="x"
      ></EbzImage>
    </div>

    <div
      class="relative z-2 w-100% h-80% flex justify-center items-center overflow-hidden md:items-end"
    >
      <div class="relative w-90vmin max-w-1280px mt-8rem">
        <!-- Envelope -->
        <EbzImage
          ref="envelopeRef"
          class="w-100% ebz-elevation-24 pointer-events-none will-change-transform"
          :src="envelopeSrc"
          :srcset="envelopeSrcset"
          :loading-src="envelopeSrcLQ"
          :metadata="envelopeMeta"
          loading="lazy"
          preload
          v-once
        ></EbzImage>

        <!-- Piece of Paper -->
        <div
          class="absolute w-65% left-50% translate-x--50% top--13% rotate--6deg"
        >
          <EbzPieceOfPaper
            class="ebz-contact-page__paper w-100%"
            variant="f"
            interactive
            clickable
            ref="paperRef"
            @click="popup = true"
            aria-haspopup="dialog"
            aria-controls="ebz-contact-popup"
            :aria-expanded="popup"
          >
            <div class="ebz-contact-page__paper-text text-h1 flex items-center">
              <EbzHighlighter query="A">
                {{ t("contact_page.title") }}

                <!-- A Sprite -->
                <template #highlight>
                  <div class="relative inline-block w-0.75em h-0.75em">
                    <EbzSprite
                      class="absolute top-50% left-50% translate--50% w-1.25em h-1.25em"
                      :src="arrowASrc"
                      :data="arrowAData"
                      :play="entered"
                      :delay="0.5"
                      animation="A"
                    ></EbzSprite>
                  </div>
                </template>
              </EbzHighlighter>
            </div>
          </EbzPieceOfPaper>
        </div>

        <!-- Arrows -->
        <div class="absolute left-50% translate-x--50% top-32%" v-once>
          <EbzSprite
            class="h-12vmin w-4vmin transform-origin-right-bottom rotate--10"
            :src="arrowASrc"
            :data="arrowAData"
            :play="entered"
            :delay="1.25"
            animation="ARROW"
          ></EbzSprite>

          <EbzSprite
            class="h-12vmin w-4vmin transform-origin-center-bottom"
            :src="arrowASrc"
            :data="arrowAData"
            :play="entered"
            :delay="1.5"
            animation="ARROW"
          ></EbzSprite>

          <div
            class="inline-block h-12vmin w-4vmin transform-origin-left-bottom rotate-10"
          >
            <EbzSprite
              class="w-100% h-100% scale-x--100"
              :src="arrowASrc"
              :data="arrowAData"
              :play="entered"
              :delay="1.75"
              animation="ARROW"
            ></EbzSprite>
          </div>
        </div>
      </div>
    </div>

    <Suspense>
      <ContactPopup id="ebz-contact-popup" v-model="popup"></ContactPopup>
    </Suspense>
  </section>
</template>

<script lang="ts" setup>
import doodlesSrc from "@/assets/img/pg-5/doodles.png?format=webp&quality=80&imagetools";
import doodlesSrcset from "@/assets/img/pg-5/doodles.png?format=webp&width=300;600;960;1280;1800&srcset&imagetools";
import doodlesSrcLQ from "@/assets/img/pg-5/doodles.png?format=webp&quality=50&width=32&imagetools";
import doodlesMeta from "@/assets/img/pg-5/doodles.png?format=webp&meta=width;height&imagetools_meta";
import tapeSpriteSrc from "@/assets/img/pg-5/tape.webp?format=webp&imagetools";
import tapeSpriteData from "@/assets/img/pg-5/tape.json";
import arrowASrc from "@/assets/img/pg-5/arrow_a.webp?format=webp&imagetools";
import arrowAData from "@/assets/img/pg-5/arrow_a.json";
import envelopeSrcset from "@/assets/img/pg-5/envelope.png?format=webp&width=300;600;960;1280&srcset&imagetools";
import envelopeSrc from "@/assets/img/pg-5/envelope.png?format=webp&imagetools";
import envelopeSrcLQ from "@/assets/img/pg-5/envelope.png?format=webp&quality=50&width=32&imagetools";
import envelopeMeta from "@/assets/img/pg-5/envelope.png?format=webp&meta=width;height&imagetools_meta";
import { EbzRippedPaper } from "@/components/EbzRippedPaper";
import { EbzSprite } from "@/components/EbzSprite";
import { EbzImage } from "@/components/EbzImage";
import { EbzPieceOfPaper } from "@/components/EbzPieceOfPaper";
import { useI18n } from "vue-i18n";
import { EbzHighlighter } from "@/components/EbzHighlighter";
import { EbzArrowUp } from "@/components/EbzArrowUp";
import { onMounted, ref } from "vue";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useCurrentElement } from "@vueuse/core";
import { useGsap } from "@/composables/useGsap";
import ContactPopup from "./ContactPopup.vue";
import { useSpriteLoader } from "@/composables/useSpriteLoader";

await useSpriteLoader(arrowASrc);

const { t } = useI18n();

const gsap = useGsap();
gsap.registerPlugin(ScrollTrigger);

const elementRef = useCurrentElement();
const envelopeRef = ref<InstanceType<typeof EbzImage>>();
const paperRef = ref<InstanceType<typeof EbzPieceOfPaper>>();
const doodlesRef = ref<HTMLElement>();
const entered = ref(false);
const popup = ref(false);

onMounted(() => {
  const el = elementRef.value;
  const envelopeEl = envelopeRef.value!.$el as Element;
  const paperEl = paperRef.value!.$el as Element;
  const doodlesEl = doodlesRef.value!;

  gsap.set(envelopeEl, {
    yPercent: 100,
  });
  gsap.set(paperEl, {
    yPercent: 25,
    opacity: 0,
    rotate: () => (Math.random() - 0.5) * 30,
  });
  gsap.set(doodlesEl, {
    clipPath: "circle(0% at 0% 100%)",
  });

  gsap
    .timeline({
      scrollTrigger: {
        trigger: el,
        start: "top 50%",
        end: "bottom bottom",
        once: true,
        scrub: 1.25,
        onKill() {
          entered.value = true;

          gsap
            .timeline()
            .to(paperEl, {
              yPercent: 0,
              opacity: 1,
              rotate: 0,
              ease: "back.out(2)",
              duration: 0.5,
            })
            .to(
              doodlesEl,
              {
                clipPath: "circle(200% at 0% 100%)",
                ease: "power1.inOut",
                duration: 2.25,
              },
              "-=0.5"
            );
        },
      } as ScrollTrigger.Vars,
    })
    .to(envelopeEl, {
      yPercent: 0,
      ease: "power2.out",
    });
});
</script>

<style lang="scss" scoped>
@use "sass:string";
@use "@/styles/tools";

.ebz-contact-page {
  &__paper {
    will-change: transform, opacity;

    &-text {
      @include tools.fluid-size(
        font-size,
        1.25rem,
        string.unquote("10vmin"),
        5rem,
        true
      );
      margin: 0;
      font-weight: 900 !important;
      user-select: none;
    }
  }
}
</style>

<i18n lang="yaml">
pt-BR:
  contact_page:
    title: CONTATO
</i18n>
