<template>
  <section class="ebz-brand-zoom-transition">
    <div class="ebz-brand-zoom-transition__overlay" ref="overlayRef">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          :viewBox="`${viewbox.x} ${viewbox.y} ${viewbox.width} ${viewbox.height}`"
          ref="svgRef"
        >
          <path
            :fill="background"
            fill-rule="evenodd"
            :d="`M ${-(offset.h / 2)},${-(offset.v / 2)} V ${
              36 + offset.v / 2
            } H ${36 + offset.h / 2} V ${-(
              offset.v / 2
            )} Z M 9.0801 14h4.2852v3.0957l-1.4961 0.63281 1.4961 0.625v3.6465h-2.8457l-0.17969-0.26953-0.17969 0.26953h-1.0801zm1.2773 1.2754v1.7129l1.7324-0.72852v-0.98438zm-5.8574 0.001953h4.0332v4.0176l-2.832 1.1953v0.31055h2.748v1.1992h-3.9492zm9.4121 0h1.0176l0.16992 0.25 0.16992-0.25h2.6777v1.0469h2e-3v0.93164h-1.168v-0.78125h-1.668v5.5254h-1.2012v-5.6758zm4.5801 0h2.3223v6.7227h-1.0156l-0.16992-0.25391-0.17188 0.25391h-2.6758v-3.3398l2.832-1.1953v-0.98633h-1.1211zm2.8672 0h1.2031v6.7227h-1.2031zm1.748 0h1.0156l0.17188 0.25391 0.16992-0.25391h2.6758v6.7227h-1.2012v-5.5215h-1.6309v5.5215h-1.2012zm4.5781 0h3.8145v1.2012l-2.3672 4.3223h2.3672v1.1992h-3.8145v-1.1992l2.3809-4.3223h-2.3809zm-21.984 1.2012v2.7129l1.6309-0.6875v-2.0254zm4.6543 1.998v2.3203h1.7344v-1.5898zm9.2578 0.28711-1.666 0.68555v1.3516h1.666z`"
          ></path>
        </svg>
      </div>
    </div>

    <div class="ebz-brand-zoom-transition__content" ref="contentRef">
      <slot></slot>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useElementSize } from "@vueuse/core";
import { computed, onMounted, ref } from "vue";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useScrollTarget } from "@/composables/useScrollTarget";
import { useGsap } from "@/composables/useGsap";

export interface BrandZoomTransitionProps {
  background?: string;
}

withDefaults(defineProps<BrandZoomTransitionProps>(), {
  background: "white",
});

const gsap = useGsap();
gsap.registerPlugin(ScrollTrigger);

const overlayRef = ref<HTMLElement>();
const svgRef = ref<SVGElement>();
const contentRef = ref<HTMLElement>();
const { width, height } = useElementSize(svgRef);
const scrollTarget = useScrollTarget();

const viewbox = {
  x: 0,
  y: 0,
  width: 36,
  height: 36,
};

const offset = computed(() => {
  const sizeH = (width.value / height.value) * viewbox.width;
  const sizeV = (height.value / width.value) * viewbox.height;

  return {
    h: isNaN(sizeH) ? 0 : Math.max(0, sizeH - viewbox.width),
    v: isNaN(sizeV) ? 0 : Math.max(0, sizeV - viewbox.height),
  };
});

onMounted(() => {
  const svg = svgRef.value!;
  const path = svg.querySelector("path")!;
  const content = contentRef.value!;

  gsap.set(path, {
    transformOrigin: "50% 52%",
  });

  gsap
    .timeline({
      scrollTrigger: {
        trigger: overlayRef.value!,
        scroller: scrollTarget.value!,
        start: "top top",
        end: "95% bottom",
        scrub: 0.5,
      },
    })
    .set(
      content,
      {
        display: "none",
      },
      0
    )
    .fromTo(
      path,
      {
        opacity: 1,
        scale: 1,
      },
      {
        scale: 40,
        opacity: 0,
        ease: "power2.in",
      }
    )
    .set(svg, {
      display: "none",
    })
    .fromTo(
      content,
      {
        display: "none",
        opacity: 0,
        scale: 0.8,
      },
      {
        display: "block",
        opacity: 1,
        scale: 1,
        ease: "power1.out",
        duration: 0.2,
      },
      "-=0.2"
    );
});

defineExpose({
  overlay: overlayRef,
  content: contentRef,
});
</script>

<style lang="scss" scoped>
.ebz-brand-zoom-transition {
  width: 100vw;

  &__overlay {
    position: relative;
    width: 100%;
    height: 200vh;
    pointer-events: none;
    margin-top: -1px;
    z-index: 2;

    > div {
      position: sticky;
      width: 100%;
      height: 50%;
      top: 0;

      > svg {
        width: 100%;
        height: 100%;

        path {
          will-change: opaciy, transform;
        }
      }
    }
  }

  &__content {
    position: relative;
    margin-top: -100vh;
    min-height: 100vh;
    z-index: 1;
    transform-origin: center;
    will-change: opacity, transform;
    contain: layout;
  }
}
</style>
