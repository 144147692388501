import type { FunctionalComponent, PropType } from "vue";
import { computed, isVNode, toRefs, useSlots } from "vue";
import { createVNodeParser } from "@/common/vnode";

export interface WordMatchOptions {
  full?: boolean;
  caseSensitive?: boolean;
}

export interface WordMatchRange {
  start: number;
  end: number;
}

export type WordMatcher = (
  word: string,
  text: string,
  options: WordMatchOptions
) => WordMatchRange | null;

export interface WordMatch extends WordMatchRange {
  text: string;
  index: number;
  queryIndex: number;
}

export interface EbzWordHighlighterProps {
  query: string | string[];
  matcher?: WordMatcher;
  split?: string | RegExp | boolean;
  full?: boolean;
  caseSensitive?: boolean;
  combine?: boolean;
  disabled?: boolean;
}

export function createWordRegExp(word: string, options: WordMatchOptions = {}) {
  let raw = `(${word.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")})`;
  if (options.full) {
    raw = `\\b${raw}\\b`;
  }
  let flags = "";
  if (!options.caseSensitive) {
    flags += "i";
  }
  return new RegExp(raw, flags);
}

export const matcher: WordMatcher = (word, text, options) => {
  if (!word) {
    return null;
  }
  const regexp = createWordRegExp(word, options);
  const execArr = regexp.exec(text);
  if (!execArr) {
    return null;
  }
  const index = execArr.index;
  if (typeof index === "undefined") {
    return null;
  }
  const match = execArr[1];
  const start = index;
  const end = start + match.length;
  return { start, end };
};

export const EbzHighlighter: FunctionalComponent<EbzWordHighlighterProps> = (
  props,
  { slots }
) => {
  const queryList = (() => {
    const words = props.query;
    if (typeof words === "string") {
      return [words];
    }
    if (props.split) {
      const splitToken = props.split === true ? /\s+/ : props.split;
      return words.flatMap((word) => word.split(splitToken));
    }
    return words;
  })();

  const vnodes = slots.default?.();

  if (!vnodes) return vnodes;

  if (props.disabled) {
    return <>{vnodes}</>;
  }

  let index = 0;

  const highlight = (match: WordMatch) => {
    if (slots.highlight) {
      return slots.highlight(match);
    }
    return <mark>{match.text}</mark>;
  };

  const parse = createVNodeParser((node) => {
    if (isVNode(node)) {
      return;
    }
    const result: any[] = [];

    if ((node ?? null) !== null) {
      let text = String(node);
      const ranges: WordMatchRange[] = [];
      const queryIndexes: number[] = [];

      // Insert a new range in the right position to keep
      // the ranges sorted by start index.
      const addRange = (start: number, end: number, queryIndex: number) => {
        let min = 0;
        let max = ranges.length - 1;
        while (min <= max) {
          const mid = (min + max) >> 1;
          const range = ranges[mid];
          if (range.start < start) {
            min = mid + 1;
          } else if (range.start > start) {
            max = mid - 1;
          } else {
            min = mid;
            break;
          }
        }
        const index = min;

        const prev = ranges[index - 1];
        const next = ranges[index];

        // Combine the ranges that overlap.
        if (props.combine) {
          if (prev && prev.end > start) {
            prev.end = end;
            return;
          }
          if (next && next.start < end) {
            next.start = start;
            return;
          }
        } else {
          // Skip the range if it overlaps with an existing range
          // and combine is not enabled.
          if (prev && prev.end > start) {
            return;
          }
          if (next && next.start < end) {
            return;
          }
        }

        ranges.splice(index, 0, { start, end });
        queryIndexes.splice(index, 0, queryIndex);
      };

      for (let qi = 0; qi < queryList.length; qi++) {
        const query = queryList[qi];
        let subtext = text;
        let length = 0;

        while (subtext.length > 0) {
          const range = props.matcher!(query, subtext, {
            full: props.full,
            caseSensitive: props.caseSensitive,
          });

          if (range) {
            const { start, end } = range;
            addRange(start + length, end + length, qi);
            subtext = subtext.substring(end);
            length += end;
          } else {
            break;
          }
        }
      }

      for (let i = 0; i < ranges.length; i++) {
        const range = ranges[i];
        const queryIndex = queryIndexes[i];
        const prev = ranges[i - 1];
        const start = range.start - (prev?.end ?? 0);
        const end = range.end - (prev?.end ?? 0);
        const before = text.substring(0, start);
        const word = text.substring(start, end);
        const after = text.substring(end);
        result.push(
          before,
          highlight({ text: word, index, queryIndex, ...range })
        );
        text = after;
        index++;
      }

      result.push(text);
    }

    return result;
  });

  const children = vnodes.map(parse);
  return <>{children}</>;
};
EbzHighlighter.props = {
  split: {
    type: Boolean,
    default: false,
  },
  matcher: {
    type: Function as PropType<WordMatcher>,
    default: matcher,
  },
  query: {
    type: [String, Array],
    required: true,
  },
};
