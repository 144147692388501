<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="ebz-symbol"
    :viewBox="`${viewbox.minX} ${viewbox.minY} ${viewbox.width} ${viewbox.height}`"
    :width="width"
    :height="height"
    :style="{
      fontSize: size,
    }"
    ref="elementRef"
  >
    <g
      fill="none"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-width="1"
      v-bind="pathAttrs"
      :style="{
        stroke: color,
      }"
    >
      <template v-if="animate">
        <path
          d="M5.647 3.86v4.082"
          pathLength="100"
          :ref="(el: any) => paths.push(el)"
        />
        <path
          d="M4.14 5.94h3.02"
          pathLength="100"
          :ref="(el: any) => paths.push(el)"
        />
        <path
          d="M4.14 7.887h4.11"
          pathLength="100"
          :ref="(el: any) => paths.push(el)"
        />
        <path
          d="M4.14 3.816h6.408"
          pathLength="100"
          :ref="(el: any) => paths.push(el)"
        />
        <path
          d="M10.048 3.86v.326l-2.177 3.7h6.323V5.433l-1.737-.584v-.625l1.737-.608V2.102h-4.57L8.845.507 5.25.5l-.638 1.432"
          pathLength="100"
          :ref="(el: any) => paths.push(el)"
        />
        <path
          d="M7.158 2.131H2.206V3.86"
          pathLength="100"
          :ref="(el: any) => paths.push(el)"
        />
        <path
          d="M4.742 3.816H.5v1.75l1.712.59v.583L.5 7.326v2.714h2.525l1.648 1.566 1.779-1.968h5.011V7.942"
          pathLength="100"
          :ref="(el: any) => paths.push(el)"
        />
      </template>

      <!-- Allow static hoisting when animation is disabled -->
      <template v-else>
        <path d="M5.647 3.86v4.082" pathLength="100" />
        <path d="M4.14 5.94h3.02" pathLength="100" />
        <path d="M4.14 7.887h4.11" pathLength="100" />
        <path d="M4.14 3.816h6.408" pathLength="100" />
        <path
          d="M10.048 3.86v.326l-2.177 3.7h6.323V5.433l-1.737-.584v-.625l1.737-.608V2.102h-4.57L8.845.507 5.25.5l-.638 1.432"
          pathLength="100"
        />
        <path d="M7.158 2.131H2.206V3.86" pathLength="100" />
        <path
          d="M4.742 3.816H.5v1.75l1.712.59v.583L.5 7.326v2.714h2.525l1.648 1.566 1.779-1.968h5.011V7.942"
          pathLength="100"
        />
      </template>
    </g>
  </svg>
</template>

<script lang="ts">
export const viewbox = {
  minX: 0,
  minY: 0,
  width: 14.694,
  height: 12.321,
};
export const ratio = viewbox.width / viewbox.height;

export interface LineAnimationOptions {
  vars?: gsap.TweenVars;
  position?: gsap.Position;
}

export type AnimationOptions = [
  LineAnimationOptions,
  LineAnimationOptions,
  LineAnimationOptions,
  LineAnimationOptions,
  LineAnimationOptions,
  LineAnimationOptions,
  LineAnimationOptions
];

export const getDefaultLineAnimations = (): AnimationOptions => [
  {
    vars: {
      duration: 0.15,
    },
  },
  {
    vars: {
      duration: 0.15,
    },
  },
  {
    vars: {
      duration: 0.15,
    },
  },
  {
    vars: {
      duration: 0.2,
    },
  },
  {
    vars: {
      duration: 0.75,
    },
  },
  {
    vars: {
      duration: 0.25,
    },
    position: "+=0.2",
  },
  {
    vars: {
      duration: 0.75,
    },
  },
];

export const makeEbzBrandSymbolProps = propsFactory(
  {
    color: String,
    size: {
      type: [String, Number],
      default: "2rem",
    },
    width: {
      type: [String, Number],
      default: "1em",
    },
    height: {
      type: [String, Number],
      default: `${(1 / ratio).toFixed(3)}em`,
    },
    pathAttrs: Object,
    animate: Boolean,
    speed: {
      type: Number,
      default: 1.25,
    },
    animationDelay: {
      type: Number,
      default: 0.25,
    },
    animationOptions: {
      type: Array as unknown as PropType<AnimationOptions>,
      default: () => getDefaultLineAnimations(),
    },
  },
  "ebz-brand-symbol"
);
</script>

<script setup lang="ts">
import { propsFactory } from "@/common/props";
import { useGsap } from "@/composables/useGsap";
import { onMounted, ref, shallowRef, type PropType } from "vue";

const props = defineProps(makeEbzBrandSymbolProps());
const emit = defineEmits(["complete"]);

const gsap = useGsap();
const paths = shallowRef<SVGPathElement[]>([]);

const elementRef = ref<SVGElement>();

onMounted(() => {
  if (props.animate) {
    const svgEl = elementRef.value!;

    gsap.context(() => {
      gsap.set(svgEl, {
        opacity: 0,
      });
      gsap.set(paths.value, {
        strokeDasharray: 100,
        strokeDashoffset: 100,
      });

      gsap.to(svgEl, {
        opacity: 1,
        duration: 0.25,
        delay: props.animationDelay,
      });

      const timeline = gsap.timeline({
        delay: props.animationDelay,
        onComplete() {
          emit("complete");
        },
      });

      paths.value.forEach((path, index) => {
        const opts = props.animationOptions[index];
        const vars: gsap.TweenVars = {
          strokeDashoffset: 0,
          ease: "linear",
          duration: 0.5,
          ...opts?.vars,
        };

        if (typeof vars.duration === "number") {
          vars.duration /= props.speed;
        }

        timeline.to(path, vars, opts.position);
      });
    }, svgEl);
  }
});
</script>
