<template>
  <component
    :is="linkComponent"
    v-bind="linkProps"
    class="ebz-nav-menu-item md:w-8rem"
    ref="itemRef"
    role="menuitem"
    @click="onClick"
  >
    <EbzSprite
      class="w-100% h-100%"
      :src="itemSpriteSrc"
      :data="itemSpriteData"
      :speed="0.75 * direction"
      :scale="1.5"
      animation="ITEM"
      v-model:play="spritePlay"
    >
      <span
        class="ebz-nav-menu-item__content text-right md:text-center"
        :style="{ color: color }"
      >
        <slot></slot>
      </span>
    </EbzSprite>
  </component>
</template>

<script setup lang="ts">
import itemSpriteSrc from "@/assets/img/menu/item.webp?format=webp&imagetools";
import itemSpriteData from "@/assets/img/menu/item.json";
import { computed, inject, ref, toRefs, watch } from "vue";
import { RouterLink, type RouterLinkProps } from "vue-router";
import { EBZ_NAV_MENU } from "../EbzNavMenu/key";
import { EbzSprite } from "../EbzSprite";
import { useElementHover, useFocus } from "@vueuse/core";

export interface NavMenuItemProps extends Partial<RouterLinkProps> {
  color?: string;
  href?: string;
  target?: string;

  // Need to be defined here due to a limitation of vue sfc compiler
  // that doesn't detect properties from extended interfaces
  // https://github.com/vuejs/core/issues/4294
  to?: RouterLinkProps["to"];
}

const props = withDefaults(defineProps<NavMenuItemProps>(), {
  color: "var(--ebz-c-white)",
});
const emit = defineEmits<(event: "click", e: PointerEvent) => void>();

const menu = inject(EBZ_NAV_MENU);

const onClick = (e: PointerEvent) => {
  if (menu) {
    menu.active.value = false;
  }
  emit("click", e);
};

const linkComponent = computed(() => {
  if (props.to) {
    return RouterLink;
  } else {
    return "a";
  }
});
const linkProps = computed(() => {
  if (linkComponent.value === RouterLink) {
    return {
      to: props.to,
      activeClass: props.activeClass,
      ariaCurrentValue: props.ariaCurrentValue,
      custom: props.custom,
      exactActiveClass: props.exactActiveClass,
      replace: props.replace,
    } as RouterLinkProps;
  } else {
    return {
      href: props.href,
      target: props.target,
    };
  }
});

const itemRef = ref<HTMLElement>();

const hovering = useElementHover(itemRef);
const { focused } = useFocus(itemRef);
const active = computed(() => hovering.value || focused.value);
const direction = computed(() => (active.value ? 1 : -1));
const spritePlay = ref(false);

watch(
  () => active.value,
  () => {
    spritePlay.value = true;
  }
);
</script>

<style lang="scss">
@use "sass:string";
@use "@/styles/tools";

$transition-duration: 0.25s;

.ebz-nav-menu-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  contain: layout;

  &__content {
    --at-apply: font-heading;
    display: block;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    height: 100%;
    @include tools.fluid-size(
      font-size,
      1rem,
      string.unquote("1rem + 1.5vmin"),
      1.25rem
    );

    * {
      display: block;
    }
  }
}
</style>
