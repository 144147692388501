<template>
  <div
    v-if="srcRatio"
    :class="[
      'ebz-image ebz-image--proportional',
      {
        'ebz-image--pixelated': isPixelated,
      },
      repeatX || repeatY
        ? {
            'ebz-image--repeat': true,
            'ebz-image--repeat-x': repeatXCount > 1,
            'ebz-image--repeat-y': repeatYCount > 1,
          }
        : {},
    ]"
    :style="[
      repeatXCount > 1
        ? {
            '--repeat-x-count': repeatXCount,
            '--max-w': maxW ? `${maxW}px` : 'none',
          }
        : {},
      repeatYCount > 1
        ? {
            '--repeat-y-count': repeatYCount,
            '--max-h': maxH ? `${maxH}px` : 'none',
          }
        : {},
      {
        '--img-w': containerWidth ? `${containerWidth}px` : undefined,
        '--img-h': containerWidth
          ? `${containerWidth * (1 / srcRatio)}px`
          : undefined,

        // This is a workaround to make the image container have the correct aspect ratio
        // before the image is loaded.
        paddingBottom:
          srcRatio && !imageElementReady
            ? `${(1 / srcRatio) * 100}%`
            : undefined,
      },
    ]"
    :aria-hidden="!alt"
    ref="containerRef"
  >
    <img
      v-for="(_, i) in repeatXCount * repeatYCount"
      :key="i"
      :class="[
        'ebz-image__img',
        {
          'ebz-image__img--flip-x':
            repeatXFlip && ~~(i % repeatXCount) % 2 === 1,
          'ebz-image__img--flip-y':
            repeatYFlip && ~~(i / repeatXCount) % 2 === 1,
        },
      ]"
      :style="{
        objectFit: fit,
        objectPosition: fitPosition,
      }"
      :ref="(el) => (imageElementReady ||= !!el)"
      :width="srcWidth"
      :height="srcHeight"
      :alt="alt"
      :src="imageLoading && loadingSrc ? loadingSrc : src"
      :srcset="imageLoading && loadingSrc ? undefined : srcset"
      :sizes="imageLoading && loadingSrc ? undefined : srcSizes"
      :loading="loading"
    />
  </div>
  <img
    v-else
    :class="[
      'ebz-image ebz-image__img',
      {
        'ebz-image--pixelated': isPixelated,
      },
    ]"
    :style="{
      objectFit: fit,
      objectPosition: fitPosition,
    }"
    :width="srcWidth"
    :height="srcHeight"
    :alt="alt"
    :src="imageLoading && loadingSrc ? loadingSrc : src"
    :srcset="imageLoading && loadingSrc ? undefined : srcset"
    :sizes="imageLoading && loadingSrc ? undefined : srcSizes"
    :ref="(el) => {
      containerRef = el as HTMLElement;
      imageElementReady ||= !!el;
    }"
    :loading="loading"
    :aria-hidden="!alt"
  />
</template>

<script lang="ts">
export const makeEbzImageProps = propsFactory(
  {
    src: {
      type: String,
      required: true,
    },
    srcset: String,
    sizes: {
      type: [String, Boolean],
      default: true,
    },
    loadingSrc: String,
    pixelatedLoading: {
      type: Boolean,
      default: true,
    },
    alt: String,
    breakpoints: {
      type: Array as PropType<(string | number)[]>,
      default: () => Object.values(theme.breakpoints ?? {}),
    },
    preload: Boolean,
    repeat: String as PropType<"none" | "x" | "y" | "both">,
    repeatFlip: String as PropType<"none" | "x" | "y" | "both">,
    width: Number,
    height: Number,
    metadata: Object as PropType<Record<string, any>>,
    fit: String as PropType<CSSProperties["objectFit"]>,
    fitPosition: String as PropType<CSSProperties["objectPosition"]>,
    loading: {
      type: String as PropType<"lazy" | "eager">,
      default: "lazy",
    },
  },
  "ebz-image"
);
</script>

<script setup lang="ts">
import { computed, ref, toRefs, type PropType } from "vue";
import { coercePixel } from "@/common/coercion";
import { theme } from "@/theme";
import { useElementSize, useImage } from "@vueuse/core";
import type { Properties as CSSProperties } from "csstype";
import { propsFactory } from "@/common/props";
import { useImgPreload } from "@/composables/useImgPreload";

export interface ImageProps {
  src: string;
  srcset?: string;
  sizes?: string | boolean;
  loadingSrc?: string;
  pixelatedLoading?: boolean;
  alt?: string;
  breakpoints?: (string | number)[];
  repeat?: "none" | "x" | "y" | "both";
  repeatFlip?: "none" | "x" | "y" | "both";
  width?: number;
  height?: number;
  metadata?: Record<string, any>;
  fit?: CSSProperties["objectFit"];
  fitPosition?: CSSProperties["objectPosition"];
  loading?: "lazy" | "eager";
}

const props = defineProps(makeEbzImageProps());
const propsRef = toRefs(props);
const containerRef = ref<HTMLElement>();
const containerSize = useElementSize(containerRef);
const containerWidth = computed(
  () => containerSize.width.value || containerRef.value?.clientWidth || 0
);
const containerHeight = computed(
  () => containerSize.height.value || containerRef.value?.clientHeight || 0
);
const imageElementReady = ref(false);

const widths = computed(() => {
  return (propsRef.srcset?.value?.match(/(\d+)w/g) ?? []).map((w) =>
    Number(w.replace("w", ""))
  );
});

const srcWidth = computed(() => {
  if (propsRef.width?.value) {
    return propsRef.width.value;
  }
  if (propsRef.metadata?.value?.width) {
    return Number(propsRef.metadata.value.width);
  }
  return undefined;
});

const srcHeight = computed(() => {
  if (propsRef.height?.value) {
    return propsRef.height.value;
  }
  if (propsRef.metadata?.value?.height) {
    return Number(propsRef.metadata.value.height);
  }
  return undefined;
});

const srcRatio = computed(() => {
  return srcWidth.value && srcHeight.value
    ? srcWidth.value / srcHeight.value
    : undefined;
});

const srcSizes = computed(() => {
  if (typeof propsRef.sizes?.value === "string") {
    return propsRef.sizes.value;
  }
  if (propsRef.sizes?.value === false || !propsRef.srcset?.value) {
    return;
  }

  const { breakpoints } = propsRef;

  return widths.value
    .map((width, i, arr) => {
      const breakpoint = coercePixel(breakpoints.value[i + 1]);
      return breakpoint
        ? `${
            i === arr.length - 1 ? "" : `(max-width: ${breakpoint})`
          } ${coercePixel(width)}`
        : "";
    })
    .filter(Boolean)
    .join(", ");
});

if (props.preload) {
  useImgPreload({
    src: props.src,
    srcset: props.srcset,
    sizes: srcSizes.value,
  });
}

const maxW = computed(() => {
  return srcWidth.value || undefined;
});

const maxH = computed(() => {
  return maxW.value && srcRatio.value ? maxW.value / srcRatio.value : undefined;
});

const repeatX = computed(() => {
  return !!(
    maxW.value &&
    (propsRef.repeat?.value === "x" || propsRef.repeat?.value === "both")
  );
});

const repeatY = computed(() => {
  return !!(
    maxH.value &&
    (propsRef.repeat?.value === "y" || propsRef.repeat?.value === "both")
  );
});

const repeatXFlip = computed(() => {
  return !!(
    repeatX.value &&
    (propsRef.repeatFlip?.value === "x" ||
      propsRef.repeatFlip?.value === "both")
  );
});

const repeatYFlip = computed(() => {
  return !!(
    repeatY.value &&
    (propsRef.repeatFlip?.value === "y" ||
      propsRef.repeatFlip?.value === "both")
  );
});

const repeatXCount = computed(() => {
  return Math.max(
    repeatX.value && maxW.value
      ? Math.ceil(containerWidth.value / maxW.value)
      : 1,
    1
  );
});

const repeatYCount = computed(() => {
  return Math.max(
    repeatY.value && maxH.value
      ? Math.ceil(containerHeight.value / maxH.value)
      : 1,
    1
  );
});

const { isLoading: imageLoading, error: imageError } = useImage(
  computed(() => ({
    src: propsRef.src.value,
    srcset: propsRef.srcset?.value,
    sizes: srcSizes.value,
  }))
);

const isPixelated = computed(
  () => imageLoading.value && propsRef.pixelatedLoading.value
);

defineExpose({
  imageLoading,
  imageError,
});
</script>

<style lang="scss">
@use "@/styles/tools";

.ebz-image {
  user-select: none;

  &--pixelated {
    @include tools.image-pixelated;
  }

  &--proportional {
    overflow: hidden;
    display: block;

    .ebz-image {
      &__img {
        width: var(--img-w);
        height: var(--img-h);
        max-width: var(--max-w);
        max-height: var(--max-h);
      }
    }
  }

  &--repeat {
    &-x,
    &-y {
      display: grid;
      grid-gap: 0;
    }

    &-x {
      grid-template-columns: repeat(var(--repeat-x-count), var(--max-w));
    }

    &-y {
      grid-template-rows: repeat(var(--repeat-y-count), var(--max-h));
    }
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;

    &--flip-x {
      transform: scaleX(-1);
    }
    &--flip-y {
      transform: scaleY(-1);
    }
  }
}
</style>
