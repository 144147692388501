<template>
  <footer
    class="ebz-footer bg-white flex items-center px-12 py-4 gap-4 lt-lg:px-6 lt-md:px-4 lt-md:flex-col lt-md:justify-center lt-md:text-center lt-md:gap-2"
  >
    <div
      class="ebz-footer__logo flex items-center object-contain overflow-hidden"
    >
      <slot name="logo">
        <EbzImage
          class="w-10rem pointer-events-none"
          :src="logoSrc"
          :metadata="logoMeta"
          :alt="t('company.name')"
          fit="contain"
        ></EbzImage>
      </slot>
    </div>

    <div class="flex-1 lt-md:hidden"></div>

    <slot>
      <span v-html="t('footer.copy')"></span>
      <a class="ebz-link whitespace-nowrap" href="#">{{
        t("policy.privacy")
      }}</a>
    </slot>
  </footer>
</template>

<script setup lang="ts">
import logoSrc from "@/assets/img/logotipo_h.png?format=webp&width=400&imagetools";
import logoMeta from "@/assets/img/logotipo_h.png?format=webp&width=400&meta=width;height&imagetools_meta";
import { useI18n } from "vue-i18n";
import { EbzImage } from "../EbzImage";
const { t } = useI18n({ useScope: "global" });
</script>

<style lang="scss">
.ebz-footer {
  position: relative;
  z-index: 300;

  &__logo {
    flex: 0 0 auto;

    img {
      max-height: 100%;
    }
  }
}
</style>
