import { coercePixel } from "@/common/coercion";
import { propsFactory } from "@/common/props";
import { computed } from "vue";

export interface DimensionProps {
  height?: number | string;
  maxHeight?: number | string;
  maxWidth?: number | string;
  minHeight?: number | string;
  minWidth?: number | string;
  width?: number | string;
}

export const makeDimensionProps = propsFactory(
  {
    height: [Number, String],
    maxHeight: [Number, String],
    maxWidth: [Number, String],
    minHeight: [Number, String],
    minWidth: [Number, String],
    width: [Number, String],
  },
  "dimension"
);

export function useDimension(props: DimensionProps) {
  const dimensionStyles = computed(() => ({
    height: coercePixel(props.height),
    maxHeight: coercePixel(props.maxHeight),
    maxWidth: coercePixel(props.maxWidth),
    minHeight: coercePixel(props.minHeight),
    minWidth: coercePixel(props.minWidth),
    width: coercePixel(props.width),
  }));

  return { dimensionStyles };
}
