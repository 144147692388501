<template>
  <BrandZoomTransition :background="background" ref="transitionRef">
    <div
      class="ebz-game-based-page px-8 py-12 color-white w-100vw min-h-100vh flex gap-10 items-center lt-md:flex-col lt-md:gap-1"
    >
      <!-- Text Section -->
      <div
        class="ebz-game-based-page__text relative flex items-center justify-end flex-grow-1 flex-shrink-0 flex-basis-50% whitespace-pre-line"
      >
        <!-- Arrows -->
        <EbzSprite
          class="w-14rem h-14rem !absolute right-5% top-[-50%] !lt-md:hidden"
          :src="spriteSrc"
          :data="spriteData"
          :play="playState"
          :speed="1.5"
          :delay="1"
          animation="ARROW"
        ></EbzSprite>

        <EbzSprite
          class="w-14rem h-14rem !absolute right-5% bottom-[-50%] md:scale-y-[-1] lt-md:bottom-10% lt-md:rotate-90 lt-md:right-[-40%]"
          :src="spriteSrc"
          :data="spriteData"
          :play="playState"
          :speed="1.5"
          :delay="1.25"
          animation="ARROW"
        ></EbzSprite>
        <!-- / Arrows -->

        <!-- Text -->
        <div class="text-h1">
          <EbzHighlighter
            :query="[
              t('game_based_page.highlight.header'),
              t('game_based_page.highlight.mark'),
            ]"
          >
            {{ t("game_based_page.text") }}

            <template #highlight="{ text, queryIndex }: WordMatch">
              <!-- Header -->
              <span v-if="queryIndex === 0" class="relative fw-black">
                <!-- DNA Paper -->
                <img
                  src="@/assets/img/pg-2/dna_paper.png?w=200"
                  class="!absolute top-[-113%] left-[-43%] w-2.75em pointer-events-none"
                />

                {{ text }}
              </span>

              <!-- Marker -->
              <EbzSprite
                v-else
                class="w-100% h-100%"
                :src="spriteSrc"
                :data="spriteData"
                :scale="1.25"
                :play="playState"
                :speed="0.75"
                :delay="0.25"
                align-x="left"
                offset-x="-18%"
                offset-y="0.1em"
                animation="TEXT_MARK"
              >
                <span class="fw-black text-1.5em">{{ text }}</span>
              </EbzSprite>
            </template>
          </EbzHighlighter>
          <!-- / Text -->
        </div>
      </div>

      <!-- List Section -->
      <div
        class="ebz-game-based-page__list flex items-center justify-start flex-grow-1 flex-shrink-0 flex-basis-50%"
      >
        <ul class="text-h1 whitespace-pre-line" ref="listRef">
          <li
            v-for="(item, itemIndex) in items"
            class="relative z-0 hover:z-10 flex gap-3 items-start py-1"
          >
            <EbzSprite
              class="h-1.25em w-0.75em"
              :src="spriteSrc"
              :data="spriteData"
              :play="playState"
              :speed="0.8"
              :delay="1.6 + itemIndex * 0.15"
              :animation="item.sign.toUpperCase()"
            ></EbzSprite>

            <span class="ebz-game-based-page__list__item-text">
              <EbzHighlighter :query="item.highlight">
                {{ item.text }}

                <template #highlight="{ text, index }: WordMatch">
                  <EbzPieceOfPaper
                    class="mx-[-0.75rem] my-[-0.5rem]"
                    :variant="getPaperVariant(itemIndex + index)"
                    interactive
                    float
                  >
                    <span
                      class="color-selectiveYellow relative px-[1.5rem] py-[0.25rem] text-0.75em top-[-0.25rem]"
                      >{{ text }}</span
                    >
                  </EbzPieceOfPaper>
                </template>
              </EbzHighlighter>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </BrandZoomTransition>
</template>

<script lang="ts" setup>
import BrandZoomTransition from "./BrandZoomTransition.vue";
import spriteSrc from "@/assets/img/pg-2/spritesheet.webp?format=webp&imagetools";
import spriteData from "@/assets/img/pg-2/spritesheet.json";
import { EbzSprite } from "@/components/EbzSprite";
import { EbzHighlighter, type WordMatch } from "@/components/EbzHighlighter";
import { useI18n } from "vue-i18n";
import { computed, onMounted, ref } from "vue";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import {
  EbzPieceOfPaper,
  type EbzPieceOfPaperVariant,
} from "@/components/EbzPieceOfPaper";
import { useGsap } from "@/composables/useGsap";
import { useSpriteLoader } from "@/composables/useSpriteLoader";

export interface GameBasedPageProps {
  background?: string;
}

withDefaults(defineProps<GameBasedPageProps>(), {
  background: "white",
});

await useSpriteLoader(spriteSrc);

const { t, tm, rt } = useI18n();

const gsap = useGsap();
gsap.registerPlugin(ScrollTrigger);

const transitionRef = ref<InstanceType<typeof BrandZoomTransition>>();
const listRef = ref<HTMLElement>();
const playState = ref(false);

const items = computed(() => {
  const list = tm("game_based_page.items") as any[];

  return list.map((item) => ({
    text: rt(item.text),
    highlight: (Array.isArray(item.highlight)
      ? item.highlight
      : [item.highlight]
    ).map((text: any) => rt(text)),
    sign: rt(item.sign),
  }));
});

const getPaperVariant = (idx: number) => {
  const variants = ["a", "e", "e", "b", "e", "c"] as EbzPieceOfPaperVariant[];
  return variants[idx % variants.length];
};

let tween: gsap.core.Tween | null = null;

onMounted(() => {
  const listEl = listRef.value!;

  gsap.set(listEl, {
    xPercent: 15,
    opacity: 0,
  });

  gsap.timeline({
    scrollTrigger: {
      trigger: transitionRef.value!.overlay!,
      start: "90% bottom",
      end: "bottom top",
      onToggle() {
        playState.value = true;

        // Stagger list
        tween = gsap.to(listEl, {
          xPercent: 0,
          opacity: 1,
          duration: 0.5,
          delay: 1.5,
          onComplete() {
            tween?.kill();
            tween = null;
          },
        });
      },
    } as ScrollTrigger.Vars,
  });
});
</script>

<style lang="scss" scoped>
@use "sass:string";
@use "@/styles/tools";

.ebz-game-based-page {
  &__text,
  &__list {
    .text-h1 {
      @include tools.fluid-size(
        font-size,
        1.75rem,
        string.unquote("1rem + 4vmin"),
        2.75rem,
        true
      );
    }
  }

  &__text {
    .text-h1 {
      line-height: 1;
    }
  }

  &__list {
    .text-h1 {
      font-weight: 500;
    }

    &__item-text {
      will-change: opacity, transform;
    }
  }
}
</style>

<i18n lang="yaml">
pt-BR:
  game_based_page:
    text: |
      O DNA
      Game
      Based
      permite
      trabalhar
      com
    highlight:
      header: O DNA
      mark: |
        Game
        Based
    items:
      - text: Menos tempo
        highlight: tempo
        sign: minus
      - text: Menos custo
        highlight: custo
        sign: minus
      - text: Mais genuinidade
        highlight: genuinidade
        sign: plus
      - text: Mais valor
        highlight: valor
        sign: plus
      - text: |
          Amplo alcance
          de soluções
        highlight:
          - alcance
          - soluções
        sign: plus
</i18n>
