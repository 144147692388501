import { resolveUnref, type MaybeComputedRef } from "@vueuse/core";
import { computed, inject } from "vue";
import {
  default as EbzOverlay,
  EBZ_OVERLAY_CONTEXT,
  type EbzOverlayContext,
} from "./EbzOverlay.vue";

export function useEbzOverlay<
  T extends EbzOverlayContext | InstanceType<typeof EbzOverlay>
>(overlay?: MaybeComputedRef<T>) {
  const scopeContext = inject(EBZ_OVERLAY_CONTEXT);

  return computed(() => {
    const _overlay = resolveUnref(overlay);
    return (_overlay ?? scopeContext ?? null) as T | null;
  });
}
