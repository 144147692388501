<template>
  <Suspense>
    <component
      class="ebz-icon"
      :is="svg"
      width="1em"
      height="1em"
      role="presentation"
      :style="{ color: color, fontSize: size }"
      v-bind="$attrs"
    ></component>

    <template #fallback>
      <EbzLoading
        class="ebz-icon ebz-icon--loading"
        :size="size"
        role="presentation"
        v-bind="$attrs"
      />
    </template>
  </Suspense>
</template>

<script lang="ts">
const files = import.meta.glob<Component>("../../assets/icons/**/*.svg");
export const icons = Object.fromEntries(
  Object.entries(files).map(([key, componentFactory]) => {
    const name = key.replace("../../assets/icons/", "").replace(".svg", "");
    return [name, defineAsyncComponent(componentFactory)];
  })
);
export const iconNames = Object.keys(icons);

export const makeEbzIconProps = propsFactory(
  {
    name: {
      type: String,
      required: true,
      validator: (value: string) => iconNames.includes(value),
    },
    color: String,
    size: [Number, String],
  },
  "ebz-icon"
);
</script>

<script setup lang="ts">
import { propsFactory } from "@/common/props";
import { computed, defineAsyncComponent, toRefs, type Component } from "vue";
import { EbzLoading } from "../EbzLoading";

const props = defineProps(makeEbzIconProps());
const propsRef = toRefs(props);

const svg = computed(() => {
  const iconName = propsRef.name.value;
  return icons[iconName];
});
</script>

<style lang="scss">
.ebz-icon {
  &:not(.ebz-icon--loading) {
    path {
      fill: currentColor;
    }
  }
}
</style>
