import { propsFactory } from "@/common/props";
import { resolveUnref, type MaybeComputedRef } from "@vueuse/core";
import { computed, warn, type PropType } from "vue";

export const makeTeleportProps = propsFactory(
  {
    attach: {
      type: [Boolean, String, Object] as PropType<boolean | string | Element>,
      default: true,
    },
  },
  "teleport"
);

export function useTeleport(
  attach: MaybeComputedRef<boolean | string | Element>
) {
  const teleportTarget = computed(() => {
    const _attach = resolveUnref(attach);

    if (!_attach || import.meta.env.SSR) return undefined;

    const targetElement =
      _attach === true
        ? document.body
        : typeof _attach === "string"
        ? document.querySelector(_attach)
        : _attach;

    if (!targetElement) {
      warn(`Can't find target element ${_attach}`);
      return undefined;
    }

    let container = targetElement.querySelector(
      ":scope > .ebz-overlay-container"
    );

    if (!container) {
      container = document.createElement("div");
      container.className = "ebz-overlay-container";
      targetElement.appendChild(container);
    }

    return container;
  });

  return { teleportTarget };
}
