import { BASE_ADMIN_URL } from "@/common/constants";
import api from "./api";

export interface ISendContactFormResponse {
  success: boolean;
  message: string;
}

export const sendContactForm = (form: Record<string, any>) => {
  return api.post<ISendContactFormResponse>("/api/contact", form, {
    baseURL: BASE_ADMIN_URL,
  });
};
