<template>
  <EbzField v-bind="fieldProps" v-on="fieldHandlers" v-slot="{ props }">
    <div class="ebz-text-field">
      <input
        v-bind="mergeProps(props, inputProps ?? {})"
        :type="type"
        :placeholder="placeholder"
        v-maska:[maskOptions]
      />
    </div>
  </EbzField>
</template>

<script lang="ts">
export const makeEbzTextInputProps = propsFactory(
  {
    type: {
      type: String,
      default: "text",
    },
    placeholder: String,
    mask: [String, Object] as PropType<string | MaskOptions>,
    inputProps: Object,
    ...makeEbzFieldProps(),
  },
  "ebz-text-field"
);
</script>

<script lang="ts" setup>
import { propsFactory } from "@/common/props";
import { useInputMask } from "@/composables/useInputMask";
import type { MaskOptions } from "maska";
import { mergeProps, toRef, type PropType } from "vue";
import { EbzField, makeEbzFieldProps } from "../EbzField";
import { useEbzField } from "../EbzField/useEbzField";
import { vMaska } from "maska";

const props = defineProps(makeEbzTextInputProps());
const emit = defineEmits(["update:modelValue", "input", "blur"]);
const mask = toRef(props, "mask");

const { fieldProps, fieldHandlers } = useEbzField(props, emit);
const maskOptions = useInputMask(mask);
</script>

<style lang="scss">
.ebz-text-field {
  width: 100%;
}
</style>
