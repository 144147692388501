<template>
  <button
    :class="['ebz-nav-btn', { 'ebz-nav-btn--active': modelValue }]"
    :disabled="disabled"
    :aria-label="modelValue ? t('nav_btn.close') : t('nav_btn.open')"
    :aria-expanded="modelValue"
    :style="{
      color: color,
    }"
    aria-haspopup="menu"
    @click="onClick"
  >
    <span class="ebz-nav-btn__bar"></span>
    <span class="ebz-nav-btn__bar"></span>
  </button>
</template>

<script lang="ts">
export const makeEbzNavButtonProps = propsFactory(
  {
    modelValue: Boolean,
    disabled: Boolean,
    color: {
      type: String,
      default: "var(--ebz-c-white)",
    },
  },
  "ebz-nav-button"
);
</script>

<script setup lang="ts">
import { propsFactory } from "@/common/props";
import { useVModel } from "@vueuse/core";
import { useI18n } from "vue-i18n";

const props = defineProps(makeEbzNavButtonProps());

const emit = defineEmits(["update:modelValue", "click"]);
const modelValue = useVModel(props, "modelValue", emit);

const { t } = useI18n();

const onClick = () => {
  modelValue.value = !modelValue.value;
  emit("click");
};
</script>

<style lang="scss">
.ebz-nav-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  width: 52px;
  height: 52px;
  padding: 6px;
  border-radius: 50%;

  &__bar {
    content: "";
    width: 100%;
    display: block;
    height: 4px;
    background-color: currentColor;
    border-radius: 4px;
    transform-origin: 50% 50%;
    transition: transform var(--ebz-easing-duration-base)
      cubic-bezier(0.34, 1.56, 0.64, 1);
  }

  &:not(.ebz-nav-btn--active):hover {
    .ebz-nav-btn {
      &__bar:nth-child(1) {
        transform: translate3d(3px, 0, 0);
      }

      &__bar:nth-child(2) {
        transform: translate3d(-3px, 0, 0);
      }
    }
  }

  &--active {
    padding-left: 4px;

    .ebz-nav-btn {
      &__bar:nth-child(1) {
        transform: translate3d(0, 5px, 0) rotate3d(0, 0, 1, 45deg);
      }

      &__bar:nth-child(2) {
        transform: translate3d(0, -5px, 0) rotate3d(0, 0, 1, -45deg);
      }
    }

    &:hover {
      .ebz-nav-btn {
        &__bar:nth-child(1) {
          transform: translate3d(0, 5px, 0) rotate3d(0, 0, 1, 45deg)
            scale3d(0.7, 1, 1);
        }

        &__bar:nth-child(2) {
          transform: translate3d(0, -5px, 0) rotate3d(0, 0, 1, -45deg)
            scale3d(0.7, 1, 1);
        }
      }
    }
  }
}
</style>

<i18n lang="yaml">
pt-BR:
  nav_btn:
    open: Abrir menu de navegação
    close: Fechar menu de navegação
</i18n>
