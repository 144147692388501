import { BASE_API_URL } from "@/common/constants";
import { join } from "@/common/url";
import { rollbar } from "@/modules/rollbar";
import axios, { isAxiosError, type CreateAxiosDefaults } from "axios";

export interface IApiSuccess<T> {
  success: true;
  data: T;
}

export interface IApiError {
  success: false;
  error: string;
  message: string;
  code: string;
}

export type IApiResponse<T> = IApiSuccess<T> | IApiError;

export const trackApiError = (error: any) => {
  if (isAxiosError(error)) {
    rollbar.error(error, error.toJSON());
  } else {
    let ctx = {};
    try {
      ctx = JSON.parse(JSON.stringify(error));
    } catch {}
    rollbar.error(error, ctx);
  }
};

export const createApi = (options: CreateAxiosDefaults = {}) => {
  const { baseURL = "", ...extraOpts } = options;

  const api = axios.create({
    baseURL: join(BASE_API_URL, baseURL),
    withCredentials: false,
    validateStatus: (status) => status >= 200 && status < 500,
    ...extraOpts,
  });

  api.interceptors.request.use(undefined, (error) => {
    trackApiError(error);
    return Promise.reject(error);
  });

  api.interceptors.response.use(undefined, (error) => {
    trackApiError(error);
    return Promise.reject(error);
  });

  return api;
};

const api = createApi();
export default api;
