<template>
  <span class="relative">
    <slot></slot>

    <EbzSprite
      class="!absolute top-0 left-0 w-100% h-100% pointer-events-none"
      :src="unerlineSrc"
      :data="unerlineData"
      :delay="underlineDelay"
      :scale="1.05"
      :speed="underlineSpeed"
      :play="play"
      animation="UNDERLINE"
      align-y="bottom"
      offset-y="-0.5rem"
    >
    </EbzSprite>

    <EbzSprite
      class="!absolute left-0 !w-90% !h-160% !top-85% pointer-events-none"
      :src="arrowSrc"
      :data="arrowData"
      :delay="arrowDelay"
      :speed="arrowSpeed"
      :play="play"
      animation="ARROW"
      align-y="bottom"
      offset-y="-1rem"
    ></EbzSprite>
  </span>
</template>

<script lang="ts" setup>
import { EbzSprite } from "@/components/EbzSprite";
import unerlineSrc from "@/assets/img/pg-1/underline.webp?format=webp&quality=85&imagetools";
import unerlineData from "@/assets/img/pg-1/underline.json";
import arrowSrc from "@/assets/img/pg-1/arrow.webp?format=webp&quality=85&imagetools";
import arrowData from "@/assets/img/pg-1/arrow.json";
import { useSpriteLoader } from "@/composables/useSpriteLoader";

export interface HeadingToolMarkProps {
  underlineDelay?: number;
  underlineSpeed?: number;
  arrowDelay?: number;
  arrowSpeed?: number;
  play?: boolean;
}

withDefaults(defineProps<HeadingToolMarkProps>(), {
  underlineDelay: 0,
  underlineSpeed: 1.75,
  arrowDelay: 0.5,
  arrowSpeed: 1,
});

await useSpriteLoader([unerlineSrc, arrowSrc]);
</script>

<style lang="scss" scoped>
.ebz-about-page-tool-mark {
  position: relative;

  .ebz-sprite {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
</style>
