import type { ExtractPropTypes } from "vue";
import { makeEbzFieldProps } from "./EbzField.vue";

export function useEbzField<
  T extends ExtractPropTypes<ReturnType<typeof makeEbzFieldProps>>
>(props: T, emit: (...args: any[]) => any) {
  const fieldHandlers = Object.fromEntries(
    ["input", "blur", "update:modelValue"].map((event) => [
      event,
      emit.bind(null, event),
    ])
  );

  const fieldProps = makeEbzFieldProps.reactiveFilter(props);

  return { fieldProps, fieldHandlers };
}
