import { propsFactory } from "@/common/props";
import { computed, ref, watch, type Ref } from "vue";

export interface UseLazyOptions {
  eager?: boolean;
}

export const makeLazyProps = propsFactory(
  {
    eager: Boolean,
  },
  "lazy"
);

export function useLazyContent(options: UseLazyOptions, active: Ref<boolean>) {
  const isBooted = ref(false);
  const hasContent = computed(
    () => isBooted.value || options.eager || active.value
  );

  watch(active, () => (isBooted.value = true));

  const onAfterLeave = () => {
    if (!options.eager) isBooted.value = false;
  };

  return { isBooted, hasContent, onAfterLeave };
}
