<template>
  <div
    class="ebz-cases-page__grid"
    :class="{
      'ebz-cases-page__grid--error': !cases.success,
    }"
  >
    <div
      v-if="!cases.success"
      class="flex gap-4 text-h6 text-selective-yellow max-w-12em"
    >
      <EbzIcon
        class="flex-shrink-0"
        size="1.5em"
        name="alert-remove-outline"
      ></EbzIcon>
      <span>{{ t("cases_grid.error") }}</span>
    </div>

    <template v-else>
      <EbzFrameThumbnail
        v-for="caseData of cases?.data"
        class="m-auto"
        aria-haspopup="dialog"
        aria-controls="cases-popup"
        :title="caseData.title"
        :aria-expanded="casePopupOpen && activeCase === caseData"
        :key="caseData.id"
        :image="caseData.image"
        :alt="caseData.title"
        :variant="caseData.frame_variant"
        @click="setPopupCase(caseData)"
      ></EbzFrameThumbnail>
    </template>

    <CasePopup
      id="cases-popup"
      v-model="casePopupOpen"
      @after-leave="() => (activeCase = null)"
    >
      <Suspense>
        <CaseContent :case-data="activeCase!"></CaseContent>

        <template #fallback>
          <div class="w-full h-full flex items-center justify-center">
            <EbzLoading size="6rem"></EbzLoading>
          </div>
        </template>
      </Suspense>
    </CasePopup>
  </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent, ref, shallowRef } from "vue";
import { EbzFrameThumbnail } from "@/components/EbzFrameThumbnail";
import { getCases, type ICase } from "@/core/api/cases.api";
import { EbzIcon } from "@/components/EbzIcon";
import { useI18n } from "vue-i18n";
import { rollbar } from "@/modules/rollbar";
import CasePopup from "./CasePopup.vue";
import { EbzLoading } from "@/components/EbzLoading";

const { t } = useI18n();

const CaseContent = defineAsyncComponent(() => import("./CaseContent.vue"));

const casePopupOpen = ref(false);
const activeCase = shallowRef<ICase | null>(null);

const setPopupCase = (caseData: ICase | null) => {
  activeCase.value = caseData ?? null;
  casePopupOpen.value = !!activeCase.value;
};

const response = await getCases({
  validateStatus: (status) => status === 200,
}).catch((res) => ({ data: { success: false }, ...res }));
const { data: cases } = response;

if (!cases.success) {
  rollbar.error(new Error("Failed to load cases"), {
    response,
  });
}
</script>

<style lang="scss">
.ebz-cases-page {
  &__grid {
    display: grid;
    @apply gap-2 grid-cols-1 md:gap-0 md:grid-cols-2 lg:grid-cols-3;

    &--error {
      grid-template-columns: 1fr !important;
    }

    > .ebz-frame-thumbnail {
      &--variant {
        &-b {
          @screen md {
            grid-row-end: span 3;
          }

          @screen lg {
            grid-row-end: span 2;
          }
        }

        &-g {
          @screen md {
            grid-column-end: span 2;
          }
        }
      }
    }
  }
}
</style>

<i18n lang="yaml">
pt-BR:
  cases_grid:
    error: Não foi possível carregar a grade de cases.
</i18n>
