import { Assets } from "@pixi/assets";
import type { Texture } from "@pixi/core";

export async function loadTexture(srcs: string[]): Promise<Texture[]>;
export async function loadTexture(src: string): Promise<Texture>;
export async function loadTexture(
  input: string | string[]
): Promise<Texture | Texture[]>;
export async function loadTexture(
  input: string | string[]
): Promise<Texture | Texture[]> {
  const load = async (src: string) => {
    // Prevent PixiJS warnings about overwriting existing resources in the resolver cache
    // as the src is not checked before adding to the resolver cache when using object syntax
    // https://github.com/pixijs/pixijs/blob/c692d0a44a8e7535d4f1cdb4f8be3fb3d3712e55/packages/assets/src/Assets.ts#L443
    return (await (Assets.resolver.hasKey(src)
      ? Assets.load(src)
      : Assets.load({
          src,
          loadParser: "loadTextures",
        }))) as Texture;
  };

  if (Array.isArray(input)) {
    return Promise.all(input.map(load));
  }
  return load(input);
}
