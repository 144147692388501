import { useHead } from "@unhead/vue";
import { toValue, type MaybeRefOrGetter } from "vue";

export interface UseImgPreloadOptions {
  src: string;
  srcset?: string;
  sizes?: string;
  crossorigin?: "" | "anonymous" | "use-credentials";
  fetchpriority?: "high" | "low" | "auto";
}

const preloaded = new Set<string>();

export function useImgPreload(
  input: MaybeRefOrGetter<
    UseImgPreloadOptions | string | (UseImgPreloadOptions | string)[]
  >
) {
  const _input = toValue(input);
  const inputArr = Array.isArray(_input) ? _input : [_input];

  for (const value of inputArr) {
    const { src, srcset, sizes, ...attrs } = (
      typeof value === "string" ? { src: value } : value
    ) as UseImgPreloadOptions;

    if (preloaded.has(src)) {
      continue;
    }
    preloaded.add(src);

    useHead({
      link: [
        {
          ...attrs,
          rel: "preload",
          as: "image",
          ...(!srcset
            ? { href: src }
            : {
                href: src,
                imagesizes: sizes,
                imagesrcset: srcset,
              }),
        },
      ],
    });
  }
}
