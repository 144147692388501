<template>
  <EbzOverlay
    class="ebz-popup"
    aria-modal="true"
    :backdrop="true"
    :content-el="contentRef"
    v-model="active"
    v-bind="overlayProps"
    @after-leave="emit('afterLeave')"
  >
    <template #default="{ contentStyle }">
      <div class="ebz-popup__outer">
        <div class="ebz-popup__inner" :style="contentStyle" ref="contentRef">
          <slot></slot>
        </div>
      </div>

      <!--
        Focusable element after content is needed by focus-trap to focus elements
        inside iframes on the popup content
        https://focus-trap.github.io/focus-trap/#demo-iframe
      -->
      <EbzPopupClose class="visually-hidden !w-0 !h-0 !scale-0">
        <span></span>
      </EbzPopupClose>
    </template>

    <template #backdrop>
      <EbzRippedPaper
        v-if="!full"
        class="ebz-popup__scrim"
        background="#F8F8F8"
      ></EbzRippedPaper>

      <div v-else class="ebz-popup__scrim bg-white"></div>
    </template>
  </EbzOverlay>
</template>

<script lang="ts">
export const makeEbzPopupProps = propsFactory(
  {
    full: Boolean,
    ...makeEbzOverlayProps({
      transition: "ebz-transition-popup-paper",
      backdropTransition: "ebz-transition-popup-backdrop",
    }),
  },
  "ebz-popup"
);
</script>

<script lang="ts" setup>
import { propsFactory } from "@/common/props";
import { useVModel } from "@vueuse/core";
import { ref } from "vue";
import { EbzOverlay, makeEbzOverlayProps } from "../EbzOverlay";
import { EbzPopupClose } from "../EbzPopupClose";
import { EbzRippedPaper } from "../EbzRippedPaper";

const props = defineProps({
  ...makeEbzPopupProps(),
});

const emit = defineEmits<{
  (event: "update:modelValue", value: boolean): void;
  (event: "afterLeave"): void;
}>();

const overlayProps = makeEbzOverlayProps.reactiveFilter(
  props,
  "modelValue",
  "contentEl",
  "backdrop"
);

const active = useVModel(props, "modelValue", emit);

const contentRef = ref<HTMLElement>();
</script>

<style lang="scss">
.ebz-popup {
  align-items: center;
  justify-content: center;
  margin: auto;

  &.ebz-overlay--scroll-blocked {
    > .ebz-overlay__content {
      > .ebz-popup__outer {
        padding-inline-end: var(--ebz-scrollbar-offset);
      }
    }
  }

  > .ebz-overlay__content {
    border-radius: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow-y: auto;
    top: 0;
    left: 0;
  }

  &__scrim {
    width: 100%;
    height: 100%;
    will-change: transform;
  }

  &__outer {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__inner {
    position: relative;
    display: flex;
    max-width: min(calc(100% - 16px), 1920px);
    width: calc(100% - 16px);
    min-height: calc(100% - 96px);
    margin: 48px auto;

    @media (min-width: 600px) {
      max-width: min(calc(100% - 48px), 1920px);
      width: calc(100% - 48px);
    }
  }
}

$paper-transition-duration: 0.5s;
$paper-transition-easing: cubic-bezier(0.33, 1, 0.68, 1);
$backdrop-transition-duration: $paper-transition-duration;
$backdrop-transition-easing: cubic-bezier(0.65, 0, 0.35, 1);

.ebz-transition-popup {
  &-backdrop {
    &-enter-active,
    &-leave-active {
      transition: background-color #{$backdrop-transition-duration} ease-in-out;

      .ebz-popup__scrim {
        transition-property: opacity, transform;
        transition-duration: #{$backdrop-transition-duration};
      }
    }

    &-enter-active {
      .ebz-popup__scrim {
        transition-timing-function: #{$backdrop-transition-easing};
      }
    }

    &-leave-active {
      .ebz-popup__scrim {
        transition-timing-function: #{$paper-transition-easing};
      }
    }

    &-enter-from,
    &-leave-to {
      background-color: rgba(#000, 0) !important;

      .ebz-popup__scrim {
        transform: translate3d(0, 100%, 0);
      }
    }

    &-enter-to,
    &-leave-from {
      .ebz-popup__scrim {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &-paper {
    &-enter-active,
    &-leave-active {
      transition-property: transform, opacity;
      transition-duration: #{$paper-transition-duration};
    }

    &-enter-active {
      transition-timing-function: #{$paper-transition-easing};
    }

    &-leave-active {
      transition-timing-function: #{$backdrop-transition-easing};
    }

    &-enter-from,
    &-leave-to {
      transform: translate3d(0, 100%, 0);
    }

    &-enter-to,
    &-leave-from {
      transform: translate3d(0, 0, 0);
    }
  }
}
</style>
