<template>
  <div class="ebz-home-layout relative">
    <HomePage>
      <template #menu>
        <EbzNavMenuItem
          v-for="item in navitems"
          :key="item.name"
          :to="`#${item.name}`"
          >{{ item.label }}</EbzNavMenuItem
        >
      </template>
    </HomePage>

    <main id="content">
      <component
        v-for="page in pages"
        :key="page.name"
        :is="page.component"
        :id="page.name"
      >
      </component>
    </main>

    <EbzFooter></EbzFooter>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { usePages } from "@/composables/usePages";
import { EbzNavMenuItem } from "@/components/EbzNavMenuItem";
import { HomePage } from "@/pages/HomePage";
import { EbzFooter } from "@/components/EbzFooter";
import { AboutPage } from "@/pages/AboutPage";
import { GameBasedPage } from "@/pages/GameBasedPage";
import { ProcessPage } from "@/pages/ProcessPage";
import { CasesPage } from "@/pages/CasesPage";
import { ContactPage } from "@/pages/ContactPage";

const { t, te } = useI18n();

const pages = usePages([
  {
    name: "about",
    component: AboutPage,
  },
  {
    name: "game-based",
    component: GameBasedPage,
    props: {
      background: "#F8F8F8",
    },
  },
  {
    name: "process",
    component: ProcessPage,
  },
  {
    name: "cases",
    component: CasesPage,
  },
  {
    name: "contact",
    component: ContactPage,
  },
]);

const navitems = pages
  .map((page) => {
    const { name } = page;
    const labelKey = `navbar.items.${name}`;

    if (te(labelKey)) {
      return {
        name,
        label: t(labelKey),
      };
    }
    return null;
  })
  .filter((item): item is NonNullable<typeof item> => item !== null);
</script>

<i18n lang="yaml">
pt-BR:
  navbar:
    items:
      about: A eBrainz
      cases: Cases
      contact: Contato
</i18n>
