import { resolveUnref, type MaybeComputedRef } from "@vueuse/core";
import type { MaskOptions } from "maska";
import { computed, type ComputedRef } from "vue";
import { useI18n } from "vue-i18n";
import { useI18nMessages } from "./useI18nMessages";

export const INPUT_MASK_KEY_PREFIX = "form.mask";

export type UseInputMaskReturn = ComputedRef<
  MaskOptions | Record<string, MaskOptions> | null
>;

export function useInputMask(
  mask: MaybeComputedRef<string | MaskOptions>
): ComputedRef<MaskOptions>;
export function useInputMask(
  mask: MaybeComputedRef<string | MaskOptions | undefined>
): ComputedRef<MaskOptions | null>;
export function useInputMask(
  mask?: MaybeComputedRef<string | MaskOptions | undefined>
): UseInputMaskReturn;
export function useInputMask(
  mask?: MaybeComputedRef<string | MaskOptions | undefined>
): UseInputMaskReturn {
  const { t, tm, rt } = useI18n({ useScope: "global" });
  const userMasks = useI18nMessages<Record<string, MaskOptions>>(
    INPUT_MASK_KEY_PREFIX
  );

  return computed(() => {
    const _mask = resolveUnref(mask);

    if (typeof _mask === "string") {
      return userMasks.value[_mask];
    }
    if (typeof _mask === "object") {
      return _mask;
    }

    return !!mask ? null : userMasks.value;
  });
}
