import {
  defineAsyncComponent,
  h,
  type AsyncComponentLoader,
  type Component,
  type DefineComponent,
} from "vue";
import { isComponent } from "@/common/vnode";
import { kebabCase } from "@/common/cases";

export interface RawPage {
  name: string;
  component: Component | DefineComponent | AsyncComponentLoader;
  props?: Record<string, any>;
}

export interface PageLoaded {
  name: string;
  component: Component;
}

export function usePages<T extends RawPage>(pages: T[]): PageLoaded[] {
  return pages.map((rawPage) => {
    const { name, props } = rawPage;
    const normalizedName = kebabCase(name);

    const normalizedComponent = isComponent(rawPage.component)
      ? rawPage.component
      : defineAsyncComponent(rawPage.component);
    const component = h(normalizedComponent, props);

    return {
      name: normalizedName,
      component,
    };
  });
}
