<template>
  <EbzPopup
    v-model="opened"
    v-bind="popupProps"
    :max-width="`min(${rippedMaskMeta.width}px, 1280px)`"
    @after-leave="emit('afterLeave')"
    full
  >
    <div
      class="ebz-case-popup relative mx-auto mt-[-2%]"
      :style="{
        backgroundImage: `url(${rippedFrameSrc})`,
      }"
      ref="elementRef"
    >
      <EbzImage
        class="!absolute !z-3 w-6.5rem h-auto top-[-0.75rem] left-[-3rem] md:top-1rem lg:top-2.5rem pointer-events-none"
        :src="tapeSrc"
        :loading-src="tapeSrcLQ"
        :metadata="tapeMetadata"
        fit="contain"
      ></EbzImage>

      <EbzPopupClose
        class="absolute z-5 top-2.75rem right-1.5rem md:top-4rem lg:top-4rem"
      ></EbzPopupClose>

      <EbzRgb
        :zoom="56"
        class="ebz-case-popup__content pb-4 pt-6rem px-4 sm:pt-6rem sm:px-8 md:px-16"
        :style="{
          maskImage: `url(${rippedMaskSrc}), linear-gradient(180deg, transparent ${
            maskHeight - 1
          }px, white ${maskHeight}px, white 100%)`,
        }"
      >
        <slot></slot>
      </EbzRgb>
    </div>
  </EbzPopup>
</template>

<script lang="ts">
export const makeCasePopupProps = propsFactory({
  ...makeEbzPopupProps(),
});
</script>

<script lang="ts" setup>
import rippedFrameSrc from "@/assets/img/pg-4/ripped_frame.png?format=webp&quality=80&imagetools";
import rippedMaskSrc from "@/assets/img/pg-4/ripped_mask.png?format=webp&quality=80&imagetools";
import rippedMaskMeta from "@/assets/img/pg-4/ripped_mask.png?format=webp&meta=width;height&imagetools_meta";
import tapeSrc from "@/assets/img/pg-4/tape.png?format=webp&width=150&imagetools";
import tapeSrcLQ from "@/assets/img/pg-4/tape.png?format=webp&width=32&quality=50&imagetools";
import tapeMetadata from "@/assets/img/pg-4/tape.png?format=webp&width=150&meta=width;height&imagetools_meta";
import { propsFactory } from "@/common/props";
import { EbzPopup, makeEbzPopupProps } from "@/components/EbzPopup";
import { useElementSize, useVModel } from "@vueuse/core";
import { computed, ref } from "vue";
import { EbzPopupClose } from "@/components/EbzPopupClose";
import { EbzImage } from "@/components/EbzImage";
import { useImgPreload } from "@/composables/useImgPreload";
import EbzRgb from "@/components/EbzRgb/EbzRgb.vue";

const props = defineProps(makeCasePopupProps());
const emit = defineEmits<{
  (event: "update:modelValue", value: boolean): void;
  (event: "afterLeave"): void;
}>();
const opened = useVModel(props, "modelValue", emit);

useImgPreload([rippedFrameSrc, rippedMaskSrc]);

const popupProps = makeEbzPopupProps.reactiveFilter(props, "modelValue");
const maskRatio = rippedMaskMeta.height / rippedMaskMeta.width;

const elementRef = ref<HTMLElement>();
const { width } = useElementSize(elementRef);
const maskHeight = computed(() => width.value * maskRatio - 1);
</script>

<style lang="scss" scoped>
.ebz-case-popup {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  color: var(--ebz-c-white);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;

  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 0 auto;
    width: 100%;
    min-height: 100%;
    z-index: 0;
    overflow: hidden;
    mask-size: contain;
    mask-position: center top;
    mask-repeat: no-repeat;
  }
}
</style>
