<template>
  <section
    class="ebz-cases-page flex w-100% min-h-100vh color-white"
    ref="elementRef"
  >
    <div class="flex flex-col gap-4 lg:flex-row m-auto">
      <!-- Title -->
      <div
        class="flex items-center justify-center p-10 lg:min-h-100vh lg:ml-2.5rem"
        v-memo="[entered]"
      >
        <div class="relative">
          <EbzSprite
            :src="spriteSrc"
            :data="spriteData"
            :scale="2"
            :speed="0.8"
            :play="entered"
            animation="MARK"
          >
            <h1
              class="ebz-cases-page__title m-0 fw-black will-change-transform-opacity"
              ref="titleRef"
            >
              {{ t("cases_page.title") }}
            </h1>
          </EbzSprite>

          <EbzSprite
            class="!absolute right-110% top-90% rotate-150deg w-50% h-100% lg:left-90% lg:bottom-140% lg:top-auto lg:rotate-0"
            :src="spriteSrc"
            :data="spriteData"
            :delay="0.5"
            :play="entered"
            animation="ARROW"
          ></EbzSprite>

          <EbzSprite
            class="!absolute left-105% top-80% rotate-100deg w-50% h-100% lg:left-83% lg:top-150% lg:bottom-auto lg:rotate-75deg"
            :src="spriteSrc"
            :data="spriteData"
            :delay="0.75"
            :play="entered"
            animation="ARROW"
          ></EbzSprite>
        </div>
      </div>

      <!-- Cases Grid -->
      <div
        class="flex items-center lg:flex-grow-1 lg:flex-basis-auto will-change-transform-opacity"
        ref="gridRef"
      >
        <CasesGrid class="w-100% p-6"></CasesGrid>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import spriteSrc from "@/assets/img/pg-4/spritesheet.webp?format=webp&imagetools";
import spriteData from "@/assets/img/pg-4/spritesheet.json";
import { EbzSprite } from "@/components/EbzSprite";
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useGsap } from "@/composables/useGsap";
import { useSpriteLoader } from "@/composables/useSpriteLoader";
import CasesGrid from "./CasesGrid.vue";

await useSpriteLoader(spriteSrc);

const gsap = useGsap();
gsap.registerPlugin(ScrollTrigger);

const { t } = useI18n();

const elementRef = ref<HTMLElement>();
const titleRef = ref<HTMLElement>();
const gridRef = ref<HTMLElement>();
const entered = ref(false);

onMounted(() => {
  const el = elementRef.value!;
  const titleEl = titleRef.value!;
  const gridEl = gridRef.value!;

  gsap.set(titleEl, {
    opacity: 0,
    yPercent: 15,
  });

  gsap
    .timeline({
      scrollTrigger: {
        trigger: titleEl,
        end: "top 94%",
        once: true,
      },
    })
    .to(titleEl, {
      opacity: 1,
      yPercent: 0,
      duration: 0.5,
      ease: "power1.out",
    })
    .add(() => {
      entered.value = true;
    });

  gsap
    .timeline({
      scrollTrigger: {
        trigger: el,
        start: "top 56%",
        end: "+=400",
        scrub: 1,
        once: true,
      },
    })
    .fromTo(
      gridEl,
      {
        opacity: 0,
        yPercent: 15,
      },
      {
        opacity: 1,
        yPercent: 0,
        ease: "power1.out",
      }
    );
});
</script>

<style lang="scss">
@use "sass:string";
@use "@/styles/tools";

.ebz-cases-page {
  &__title {
    @include tools.fluid-size(
      font-size,
      2rem,
      string.unquote("1rem + 5vw"),
      3.5rem,
      true
    );
  }
}
</style>

<i18n lang="yaml">
pt-BR:
  cases_page:
    title: CASES
</i18n>
