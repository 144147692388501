import {
  computed,
  getCurrentInstance,
  nextTick,
  onScopeDispose,
  type Ref,
} from "vue";
import type {
  NavigationGuardNext,
  RouteLocationNormalizedLoaded,
  Router,
} from "vue-router";

export function useMaybeRoute(): Ref<
  RouteLocationNormalizedLoaded | undefined
> {
  const vm = getCurrentInstance();

  return computed(() => vm?.proxy?.$route);
}

export function useMaybeRouter(): Router | undefined {
  return getCurrentInstance()?.proxy?.$router;
}

let inTransition = false;
export function useBackButton(
  router: Router | undefined,
  cb: (next: NavigationGuardNext) => void
) {
  let popped = false;
  let removeBefore: (() => void) | undefined;
  let removeAfter: (() => void) | undefined;

  if (!import.meta.env.SSR) {
    nextTick(() => {
      window.addEventListener("popstate", onPopstate);
      removeBefore = router?.beforeEach((to, from, next) => {
        if (!inTransition) {
          setTimeout(() => (popped ? cb(next) : next()));
        } else {
          popped ? cb(next) : next();
        }
        inTransition = true;
      });
      removeAfter = router?.afterEach(() => {
        inTransition = false;
      });
    });

    onScopeDispose(() => {
      window.removeEventListener("popstate", onPopstate);
      removeBefore?.();
      removeAfter?.();
    });
  }

  function onPopstate(e: PopStateEvent) {
    if (e.state?.replaced) return;

    popped = true;
    setTimeout(() => (popped = false));
  }
}
