import { resolveUnref, type MaybeComputedRef } from "@vueuse/core";
import { computed, inject, ref, watch, type Ref } from "vue";

const targetMap = new Map<string, Ref<any>>();

export function useScrollId(id?: MaybeComputedRef<string>) {
  if (!id) {
    id = inject("scrollTargetId", "app");
  }

  return computed(() => resolveUnref(id!));
}

function getOrCreateRef<T extends HTMLElement | undefined>(id: string) {
  if (!targetMap.has(id)) {
    targetMap.set(id, ref());
  }
  return targetMap.get(id)! as Ref<T>;
}

export function useScrollTarget<T extends HTMLElement | undefined>(
  id?: MaybeComputedRef<string>
) {
  const targetId = useScrollId(id);
  return computed(() => getOrCreateRef<T>(resolveUnref(targetId)).value);
}

export function useScrollRef<T extends HTMLElement | undefined>(
  id?: MaybeComputedRef<string>
) {
  const targetId = useScrollId(id);
  let targetRef: Ref<T>;

  watch(
    targetId,
    (newId, oldId) => {
      if (oldId && targetMap.has(oldId)) {
        const oldTarget = targetMap.get(oldId)!;
        oldTarget.value = undefined;
        targetMap.delete(oldId);
      }
      targetRef = getOrCreateRef(newId);
    },
    { immediate: true }
  );

  return computed({
    get() {
      return targetRef.value;
    },
    set(value) {
      targetRef.value = value;
    },
  });
}
