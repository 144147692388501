import type { AxiosRequestConfig } from "axios";
import api, { type IApiResponse } from "./api";

export interface ICase {
  id: string;
  title: string;
  image: string;
  image_lq?: string;
  frame_variant?: string;
}

export const getCases = (options?: AxiosRequestConfig) => {
  return api.get<IApiResponse<ICase[]>>("cases.json", {
    responseType: "json",
  });
};

export const getCaseContent = (id: string) => {
  return api.get<string>(`cases/${id}.html`, {
    responseType: "text",
  });
};
