<template>
  <a
    class="ebz-arrow-up"
    :class="{ 'ebz-arrow-up--disabled': disabled }"
    :aria-label="t('ebz_arrow_up.aria_label')"
    :aria-disabled="disabled ? 'true' : undefined"
    href="#"
    @click="onClick"
  >
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 310 310"
      aria-hidden="true"
      focusable="false"
    >
      <path
        d="M155.944 104.409c7.743 5.976 12.274 17.016 17.467 25.214l34.974 55.47c6.064 9.217 12.45 28.883 27.018 18.159 10.311-7.588-9.989-27.356-14.161-35.304-10.554-20.104-25.033-37.974-36.603-57.487-4.972-8.386-7.204-19.72-12.956-27.231-6.608-8.629-15.566-20.114-27.841-15.119-10.654 4.336-14.737 23.191-17.274 33.273-7.019 27.894-19.6 54.938-30.066 81.693-3.258 8.327-8.087 15.91-11.332 24.205-.982 2.51-2.845 6.993-.802 9.457 4.485 5.408 16.152-12.433 18.171-15.508 11.824-18.003 20.624-37.381 28.381-57.487 1.012-2.622 4.351-18.834 9.024-16.099 2.543 1.488 2.719 6.498 3.136 9.04 1.527 9.323 1.96 18.867 3.205 28.239 4.503 33.876 1.589 68.838 1.589 102.872 0 7.993.614 16.271-.428 24.205-.457 3.473-1.795 9.468.608 12.503 3.363 4.246 11.784 1.378 12.701-3.445 2.374-12.493.695-28.408 1.198-41.331 1.35-34.668.354-69.339-2.068-103.881-.821-11.725-1.939-23.625-3.107-35.299-.577-5.77-2.541-10.396-.836-16.137z"
        paint-order="stroke fill markers"
        fill="currentColor"
      />
      <circle
        cx="155"
        cy="155"
        r="143"
        fill="none"
        stroke="currentColor"
        stroke-width="12"
        pathLength="100"
        stroke-dasharray="100"
        stroke-dashoffset="0"
        transform-origin="center"
        transform="rotate(90 0 0)"
      ></circle>
    </svg>
  </a>
</template>

<script lang="ts">
export const makeEbzArrowUpProps = propsFactory(
  {
    disabled: Boolean,
    hash: {
      type: String,
      default: "",
    },
    top: {
      type: Number,
      default: 0,
    },
    left: Number,
    options: Object as PropType<ScrollToOptions>,
  },
  "ebz-arrow-up"
);
</script>

<script lang="ts" setup>
import { propsFactory } from "@/common/props";
import type { PropType } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const props = defineProps(makeEbzArrowUpProps());

const { t } = useI18n();
const router = useRouter();

const onClick = (e: MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();

  if (!props.disabled) {
    if (props.options) {
      window.scrollTo(props.options);
    } else {
      window.scrollTo({
        top: props.top,
        left: props.left,
        behavior: "smooth",
      });
    }

    router.push({ path: "", hash: props.hash });
  }
};
</script>

<style lang="scss">
$transition-duration: 0.2s;
$transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
$transition-delay: 0.09s;

.ebz-arrow-up {
  display: inline-block;
  width: 6.5vmin;
  height: 6.5vmin;
  min-width: 2.5rem;
  min-height: 2.5rem;
  max-width: 6rem;
  max-height: 6rem;
  transition: color #{$transition-duration} #{$transition-timing-function};
  cursor: pointer;

  &--disabled {
    opacity: 0.5;
    cursor: default !important;
    pointer-events: none;
  }

  path {
    transition-property: transform;
    transition-duration: #{$transition-duration};
    transition-timing-function: #{$transition-timing-function};
    transition-delay: 0s;
  }

  circle {
    transition-property: stroke-dashoffset;
    transition-duration: #{$transition-duration};
    transition-timing-function: #{$transition-timing-function};
    transition-delay: #{$transition-delay};
  }

  &:not(.ebz-arrow-up--disabled) {
    &:hover,
    &:focus-visible {
      color: var(--ebz-c-selective-yellow);

      path {
        transform: translate3d(0, -15%, 0);
        transition-delay: #{$transition-delay};
      }

      circle {
        stroke-dashoffset: 100;
        transition-delay: 0s;
      }
    }
  }
}
</style>

<i18n lang="yaml">
pt-BR:
  ebz_arrow_up:
    aria_label: Voltar ao topo da página
</i18n>
