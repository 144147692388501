<template>
  <button
    class="ebz-popup-close"
    :disabled="disabled"
    @click="onClick"
    :aria-label="label || t('ebz-popup-close.label')"
  >
    <slot>
      <EbzImage
        class="ebz-popup-close__x w-100% h-100%"
        :src="popupCloseSrc"
        :loading-src="popupCloseSrcLQ"
        :metadata="popupCloseMetadata"
      ></EbzImage>
    </slot>
  </button>
</template>

<script lang="ts">
export const makeEbzPopupCloseProps = propsFactory(
  {
    disabled: Boolean,
    label: String,
    overlay: Object as PropType<
      InstanceType<typeof EbzOverlay> | EbzOverlayContext
    >,
  },
  "ebz-popup-close"
);
</script>

<script lang="ts" setup>
import popupCloseSrc from "@/assets/img/pg-3/popup-close.png?format=webp&quality=85&width=64&imagetools";
import popupCloseSrcLQ from "@/assets/img/pg-3/popup-close.png?format=webp&quality=50&width=32&imagetools";
import popupCloseMetadata from "@/assets/img/pg-3/popup-close.png?format=webp&width=64&metadata&imagetools";
import { propsFactory } from "@/common/props";
import { toRef, type PropType, type Ref } from "vue";
import {
  EbzOverlay,
  useEbzOverlay,
  type EbzOverlayContext,
} from "../EbzOverlay";
import { EbzImage } from "../EbzImage";
import { useI18n } from "vue-i18n";

const props = defineProps(makeEbzPopupCloseProps());
const overlay = toRef(props, "overlay");

const { t } = useI18n();
const context = useEbzOverlay(overlay as Ref<EbzOverlayContext>);

const onClick = () => {
  if (!props.disabled) {
    context.value?.close();
  }
};
</script>

<style lang="scss">
.ebz-popup-close {
  cursor: pointer;
  width: 3rem;
  height: 3rem;

  &[disabled] {
    cursor: block;
    opacity: 0.75;
  }

  > .ebz-popup-close__x {
    transition: transform var(--ebz-easing-duration-base) ease;
    transform: scale(1);
  }

  &:hover {
    > .ebz-popup-close__x {
      transform: scale(0.75);
    }
  }
}
</style>

<i18n lang="yaml">
pt-BR:
  ebz-popup-close:
    label: Fechar popup
</i18n>
