<template>
  <EbzPopup
    v-model="opened"
    :max-width="1080"
    initial-focus="#ebz-contact-field-name"
  >
    <EbzRgb
      :zoom="80"
      class="ebz-contact-popup w-100% h-100% flex flex-col items-center justify-center color-white ebz-elevation-12"
    >
      <!-- Success Alert -->
      <div
        :style="{ display: 'none' }"
        class="absolute z-2 w-100% h-100% top-0 left-0 flex items-center justify-center"
        ref="alertWrapperRef"
        role="alert"
      >
        <div class="pointer-events-none" ref="alertRef">
          <EbzPieceOfPaper variant="f">
            <h6 class="px-12 mb-0 text-center select-none">
              {{ t("contact_popup.success_message") }}
            </h6>
          </EbzPieceOfPaper>
        </div>
      </div>

      <!-- Header -->
      <div class="w-100% flex items-start justify-between px-4 pt-4">
        <EbzPopupClose></EbzPopupClose>

        <EbzBrandSymbol
          size="6.5rem"
          color="var(--ebz-c-white)"
        ></EbzBrandSymbol>
      </div>

      <!-- Form -->
      <form class="w-100% mt-1.75rem" @submit="onSubmit" ref="formRef">
        <div class="w-100% flex flex-col px-4 md:pl-18 md:pr-24">
          <ContactFormField
            name="name"
            :label="t('contact_popup.fields.name.label')"
            :delay="delay"
            v-slot="props"
          >
            <EbzTextField
              v-bind="props"
              rules="required|min:3|max:64"
              :placeholder="t('contact_popup.fields.name.placeholder')"
              :label="t('contact_popup.fields.name.label')"
              :disabled="isFetching"
            ></EbzTextField>
          </ContactFormField>

          <ContactFormField
            name="email"
            :label="t('contact_popup.fields.email.label')"
            :scale="1.5"
            :delay="delay + 0.25"
            v-slot="props"
          >
            <EbzTextField
              v-bind="props"
              rules="required|email|max:128"
              :placeholder="t('contact_popup.fields.email.placeholder')"
              :label="t('contact_popup.fields.email.label')"
              :disabled="isFetching"
            ></EbzTextField>
          </ContactFormField>

          <ContactFormField
            name="phone"
            :label="t('contact_popup.fields.phone.label')"
            :scale="1.5"
            :delay="delay + 0.5"
            offset-y="0.5em"
            v-slot="props"
          >
            <EbzTextField
              v-bind="props"
              rules="required|phone"
              mask="phone"
              :placeholder="t('contact_popup.fields.phone.placeholder')"
              :label="t('contact_popup.fields.phone.label')"
              :disabled="isFetching"
            ></EbzTextField>
          </ContactFormField>

          <ContactFormField
            name="subject"
            :label="t('contact_popup.fields.subject.label')"
            :scale="1.9"
            :delay="delay + 0.75"
            v-slot="props"
          >
            <EbzTextField
              v-bind="props"
              rules="required|min:2"
              :placeholder="t('contact_popup.fields.subject.placeholder')"
              :label="t('contact_popup.fields.subject.label')"
              :disabled="isFetching"
            ></EbzTextField>
          </ContactFormField>

          <ContactFormField
            name="message"
            :label="t('contact_popup.fields.message.label')"
            :scale="1.25"
            :delay="delay + 1"
            offset-y="0.75em"
            offset-x="-0.5em"
            v-slot="props"
          >
            <EbzTextarea
              v-bind="props"
              rules="required|min:5"
              :placeholder="t('contact_popup.fields.message.placeholder')"
              :label="t('contact_popup.fields.message.label')"
              rows="5"
              max-rows="10"
              :disabled="isFetching"
            ></EbzTextarea>
          </ContactFormField>
        </div>

        <div class="w-100% flex justify-end px-8 pb-4">
          <button
            class="ebz-contact-popup__submit"
            :disabled="!meta.valid || isFetching"
            :aria-label="t('contact_popup.submit')"
            type="submit"
          >
            <EbzSprite
              class="w-12rem h-4rem"
              :src="spriteSrc"
              :data="spriteData"
              :delay="delay + 1.25"
              animation="SEND"
            ></EbzSprite>
          </button>
        </div>
      </form>

      <div class="w-100% px-8 py-2 bg-white" v-if="!!error">
        <p class="w-100% mb-0 text-center text-radical-red lh-1em">
          <small>{{ t("contact_popup.error_message") }}</small>
        </p>
      </div>
    </EbzRgb>
  </EbzPopup>
</template>

<script lang="ts">
export const makeContactPopupProps = propsFactory({
  delay: {
    type: Number,
    default: 0.25,
  },
  ...makeEbzPopupProps(),
});
</script>

<script lang="ts" setup>
import spriteSrc from "@/assets/img/pg-5/popup/spritesheet.webp?format=webp&imagetools";
import spriteData from "@/assets/img/pg-5/popup/spritesheet.json";
import { propsFactory } from "@/common/props";
import { EbzBrandSymbol } from "@/components/EbzBrandSymbol";
import { EbzPopup, makeEbzPopupProps } from "@/components/EbzPopup";
import { EbzSprite } from "@/components/EbzSprite";
import { EbzTextarea } from "@/components/EbzTextarea";
import { EbzTextField } from "@/components/EbzTextField";
import { useVModel } from "@vueuse/core";
import { useForm } from "vee-validate";
import ContactFormField from "./ContactFormField.vue";
import { useI18n } from "vue-i18n";
import { EbzPopupClose } from "@/components/EbzPopupClose";
import { EbzPieceOfPaper } from "@/components/EbzPieceOfPaper";
import { nextTick, ref } from "vue";
import { useGsap } from "@/composables/useGsap";
import { sendContactForm } from "@/core/api/contact.api";
import { AxiosError } from "axios";
import { rollbar } from "@/modules/rollbar";
import EbzRgb from "@/components/EbzRgb/EbzRgb.vue";
import { useSpriteLoader } from "@/composables/useSpriteLoader";

const props = defineProps(makeContactPopupProps());
const emit = defineEmits<{
  (event: "update:modelValue", value: boolean): void;
}>();
const opened = useVModel(props, "modelValue", emit);

await useSpriteLoader(spriteSrc);

const { t } = useI18n();
const gsap = useGsap();

const alertWrapperRef = ref<HTMLElement>();
const alertRef = ref<HTMLElement>();
const formRef = ref<HTMLFormElement>();
const { handleSubmit, meta, resetForm } = useForm({
  keepValuesOnUnmount: true,
});

const isFetching = ref(false);
const error = ref<any>(null);

const showSuccessAlert = () => {
  const form = formRef.value!;
  const wrapper = alertWrapperRef.value!;
  const alert = alertRef.value!;

  gsap.set(wrapper, {
    display: "",
  });

  gsap
    .timeline()
    .fromTo(
      form,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        duration: 0.5,
        pointerEvents: "none",
        onComplete() {
          resetForm();
        },
      }
    )
    .fromTo(
      alert,
      {
        opacity: 0,
        yPercent: 75,
        rotate: () => (Math.random() - 0.5) * 30,
      },
      {
        opacity: 1,
        yPercent: 0,
        rotate: 0,
        duration: 0.5,
        ease: "back.out(3)",
      },
      "-=0.25"
    )
    .to(
      alert,
      {
        opacity: 0,
        yPercent: 50,
        duration: 0.5,
        ease: "power2.inOut",
      },
      `+=3`
    )
    .to(wrapper, {
      display: "none",
      duration: 0,
    })
    .add(() => {
      opened.value = false;
    }, "-=0.1");
};

const onSubmit = handleSubmit(async (values) => {
  isFetching.value = true;
  try {
    const response = await sendContactForm(values);

    if (!response.data.success) {
      throw new AxiosError(
        "Could not send contact form",
        response.statusText,
        response.config,
        response.request,
        response
      );
    }

    nextTick(() => {
      showSuccessAlert();
    });
  } catch (e: any) {
    error.value = e;
    rollbar.error(e);
  } finally {
    isFetching.value = false;
  }
});
</script>

<style lang="scss" scoped>
.ebz-contact-popup {
  border-radius: 4px 0 0;

  :deep(.ebz-field__input::placeholder) {
    color: rgba(#fff, 0.75);
  }

  &__submit {
    position: relative;
    transition-property: transform, filter;
    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.45, 0, 0.55, 1);
    will-change: transform, filter;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 50%;
      height: 2px;
      bottom: 0.25rem;
      left: 0;
      border-radius: 2px;
      transform: scaleX(0);
      transform-origin: 0 center;
      transition: transform var(--ebz-easing-duration-base)
        cubic-bezier(0.45, 0, 0.55, 1);
      background-color: var(--ebz-c-white);
    }

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.65;
    }

    &:not([disabled]) {
      &:hover::after {
        transform: scaleX(1);
      }
    }
  }
}
</style>

<i18n lang="yaml">
pt-BR:
  contact_popup:
    success_message: E-mail enviado com sucesso!
    success_message_duration: 4
    error_message: Não foi possível enviar o e-mail. Por favor, tente novamente mais tarde.
    fields:
      name:
        label: Nome
        placeholder: Seu Nome
      email:
        label: E-mail
        placeholder: email{'@'}exemplo.com
      phone:
        label: Telefone
        placeholder: (99) 99999-9999
      subject:
        label: Assunto
        placeholder: Como podemos ajudar?
      message:
        label: Mensagem
        placeholder: Digite sua mensagem...
    submit: Enviar mensagem
</i18n>
