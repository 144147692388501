<template>
  <div class="ebz-about-page__heading text-h3" ref="elementRef">
    <EbzHighlighter
      :query="[t('about_page.highlight.tool'), t('about_page.highlight.title')]"
    >
      {{ t("about_page.text") }}

      <template #highlight="{ text, queryIndex }: WordMatch">
        <!-- Title -->
        <template v-if="queryIndex === 1">
          <HeadingProcessMark
            class="whitespace-nowrap"
            :delay="delay"
            :speed="0.75"
            :play="play"
            >{{ text }}</HeadingProcessMark
          >
        </template>

        <!-- Tool -->
        <template v-else>
          <HeadingToolMark
            :play="play"
            :underline-delay="delay + 0.4"
            :arrow-delay="delay + 0.6"
            >{{ text }}</HeadingToolMark
          >
        </template>
      </template>
    </EbzHighlighter>
  </div>
</template>

<script lang="ts" setup>
import { EbzHighlighter, type WordMatch } from "@/components/EbzHighlighter";
import { useI18n } from "vue-i18n";
import HeadingProcessMark from "./HeadingProcessMark.vue";
import HeadingToolMark from "./HeadingToolMark.vue";

export interface HeadingProps {
  delay?: number;
  play?: boolean;
}

withDefaults(defineProps<HeadingProps>(), {
  delay: 0,
});

const { t } = useI18n();
</script>

<i18n lang="yaml">
pt-BR:
  about_page:
    text: |
      Criamos, projetamos e executamos,
      soluções customizadas e inovadoras usando
      o universo de games como ferramenta.
    highlight:
      title: Criamos, projetamos e executamos,
      tool: ferramenta.
</i18n>
