import {
  effectScope,
  nextTick,
  onScopeDispose,
  watch,
  type EffectScope,
  type WatchSource,
} from "vue";

export function useToggleScope(
  source: WatchSource<boolean>,
  fn: (reset: () => void) => void,
  waitNextTick = false
) {
  let scope: EffectScope | undefined;
  async function start() {
    scope = effectScope();

    if (waitNextTick) {
      await nextTick();
    }

    scope.active &&
      scope.run(() =>
        fn.length
          ? fn(() => {
              scope?.stop();
              start();
            })
          : (fn as any)()
      );
  }

  watch(
    source,
    (active) => {
      if (active && !scope) {
        start();
      } else if (!active) {
        scope?.stop();
        scope = undefined;
      }
    },
    { immediate: true }
  );

  onScopeDispose(() => {
    scope?.stop();
  });
}
