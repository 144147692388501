<template>
  <slot
    name="activator"
    role="button"
    :aria--controls="id"
    :aria--expanded="hasContent"
    @click="tryToggle()"
  ></slot>

  <Teleport :to="teleportTarget" :disabled="!teleportTarget">
    <div
      v-if="hasContent"
      v-bind="$attrs"
      ref="root"
      role="dialog"
      :id="id"
      :style="{
        zIndex: zIndex,
      }"
      :class="[
        'ebz-overlay',
        {
          'ebz-overlay--active': active,
          'ebz-overlay--absolute': absolute || contained,
          'ebz-overlay--contained': contained,
        },
      ]"
    >
      <EbzTransition
        appear
        :transition="backdropTransition"
        @after-leave="backdropLeaveState = true"
        @before-enter="backdropLeaveState = false"
      >
        <div
          v-if="active && !!backdrop"
          class="ebz-overlay__backdrop"
          :style="{
            backgroundColor:
              typeof backdrop === 'string' ? backdrop : undefined,
          }"
        >
          <slot name="backdrop"></slot>
        </div>
      </EbzTransition>

      <EbzTransition
        appear
        persisted
        :transition="transition"
        @after-leave="contentLeaveState = true"
        @before-enter="contentLeaveState = false"
      >
        <div
          v-show="active"
          :class="['ebz-overlay__content', contentClass]"
          :style="contentEl ? undefined : dimensionStyles"
          ref="contentRef"
        >
          <slot :content-style="contentEl ? dimensionStyles : undefined"></slot>
        </div>
      </EbzTransition>
    </div>
  </Teleport>
</template>

<script lang="ts">
const ebzTransitionProps = makeEbzTransitionProps();

export const makeEbzOverlayProps = propsFactory(
  {
    id: {
      type: String,
      default: () => `ebz-overlay-${getUid()}`,
    },
    modelValue: Boolean,
    contentClass: null,
    contentEl: [String, Object] as PropType<string | HTMLElement>,
    persistent: Boolean,
    absolute: Boolean,
    contained: Boolean,
    trapFocus: {
      type: Boolean,
      default: true,
    },
    zIndex: {
      type: [Number, String],
      default: 2000,
    },
    backdrop: {
      type: [String, Boolean],
      default: true,
    },
    closeOnBack: {
      type: Boolean,
      default: true,
    },
    transition: ebzTransitionProps.transition,
    backdropTransition: ebzTransitionProps.transition,
    initialFocus: {
      type: [String, Object, Boolean, Function] as PropType<
        UseFocusTrapOptions["initialFocus"]
      >,
      default: false,
    },
    ...makeTeleportProps(),
    ...makeDimensionProps(),
    ...makeLazyProps(),
  },
  "ebz-overlay"
);

export interface EbzOverlayContext {
  id: Ref<string>;
  active: Ref<boolean>;
  persistent: Ref<boolean>;
  contained: Ref<boolean>;
  absolute: Ref<boolean>;
  visible: Ref<boolean>;
  open(): void;
  close(): void;
  toggle(): void;
  clickOutside(): void;
}

export const EBZ_OVERLAY_CONTEXT = Symbol(
  "EbzOverlayContext"
) as InjectionKey<EbzOverlayContext>;

export default {
  inheritAttrs: false,
};
</script>

<script lang="ts" setup>
import { useLazyContent, makeLazyProps } from "@/composables/useLazyContent";
import { makeTeleportProps, useTeleport } from "@/composables/useTeleport";
import {
  computedAsync,
  onClickOutside,
  useMagicKeys,
  useVModel,
} from "@vueuse/core";
import { getScrollParents, hasScrollbar } from "@/common/scroll";
import {
  computed,
  nextTick,
  onScopeDispose,
  provide,
  ref,
  toRef,
  warn,
  watch,
  type InjectionKey,
  type PropType,
  type Ref,
} from "vue";
import { EbzTransition, makeEbzTransitionProps } from "../EbzTransition";
import { coercePixel } from "@/common/coercion";
import { useDimension, makeDimensionProps } from "@/composables/useDimension";
import { useToggleScope } from "@/composables/useToggleScope";
import { useBackButton, useMaybeRouter } from "@/composables/useRouter";
import { propsFactory } from "@/common/props";
import {
  useFocusTrap,
  type UseFocusTrapOptions,
} from "@vueuse/integrations/useFocusTrap";
import { getUid } from "@/common/uid";

const props = defineProps({
  ...makeEbzOverlayProps(),
});

const emit = defineEmits<{
  (event: "update:modelValue", value: boolean): void;
  (event: "click:outside", e: PointerEvent): void;
  (event: "afterLeave"): void;
}>();

const active = useVModel(props, "modelValue", emit);
const attach = toRef(props, "attach");
const persistent = toRef(props, "persistent");
const contained = toRef(props, "contained");

const root = ref<HTMLElement>();
const contentRef = ref<HTMLElement>();
const { teleportTarget } = useTeleport(
  computed(() => (contained.value ? false : attach.value))
);
const backdropLeaveState = ref(false);
const contentLeaveState = ref(false);
const { hasContent, onAfterLeave } = useLazyContent(props, active);
const { dimensionStyles } = useDimension(props);

const targetContentEl = computedAsync(async () => {
  const contentEl = contentRef.value;
  const userContentEl = props.contentEl;
  let element: HTMLElement | null = null;

  if (!active.value || !contentEl) {
    return null;
  }

  await nextTick();

  if (userContentEl) {
    if (typeof userContentEl === "string") {
      element = contentEl?.querySelector(userContentEl) ?? null;
    } else {
      element = userContentEl;
    }

    if (!element) {
      warn(`[EbzOverlay] provided content could not be found`);
    }
  }

  return element ?? contentEl;
});

watch(
  () => backdropLeaveState.value && contentLeaveState.value,
  (leave) => {
    if (leave) {
      onAfterLeave();
      emit("afterLeave");
    }
  }
);

const tryClose = () => {
  if (!persistent.value) active.value = false;
};

const tryToggle = () => {
  if (!active.value) active.value = true;
  else tryClose();
};

// Trap focus
const { activate, deactivate } = useFocusTrap(contentRef, {
  escapeDeactivates: false,
  initialFocus: props.initialFocus,
  immediate: active.value && props.trapFocus,
});

useToggleScope(
  () => active.value && props.trapFocus,
  () => {
    activate();
    onScopeDispose(() => {
      deactivate();
    });
  },
  true
);

// Close overlay on click outside
onClickOutside(targetContentEl, (e) => {
  emit("click:outside", e);
  tryClose();
});

// Close on press ESC
const { escape } = useMagicKeys();
watch(escape, () => {
  if (active.value) tryClose();
});

// Close on back button
const router = useMaybeRouter();
useToggleScope(
  () => props.closeOnBack,
  () => {
    useBackButton(router, (next) => {
      if (active.value) {
        next(false);
        tryClose();
      } else {
        next();
      }
    });
  }
);

// Block scroll
useToggleScope(
  () => active.value,
  () => {
    const offsetParent = root.value?.offsetParent;
    const scrollElements = [
      ...new Set([
        ...getScrollParents(
          contentRef.value,
          props.contained ? offsetParent : undefined
        ),
      ]),
    ].filter((el) => !el.classList.contains("ebz-overlay-scroll-blocked"));

    const scrollableParent = ((el) => hasScrollbar(el) && el)(
      offsetParent || document.documentElement
    );
    if (scrollableParent) {
      root.value!.classList.add("ebz-overlay--scroll-blocked");
    }

    scrollElements.forEach((el, i) => {
      el.style.setProperty("--ebz-body-scroll-x", coercePixel(-el.scrollLeft));
      el.style.setProperty("--ebz-body-scroll-y", coercePixel(-el.scrollTop));
      el.classList.add("ebz-overlay-scroll-blocked");
    });

    onScopeDispose(() => {
      scrollElements.forEach((el, i) => {
        const x = parseFloat(el.style.getPropertyValue("--ebz-body-scroll-x"));
        const y = parseFloat(el.style.getPropertyValue("--ebz-body-scroll-y"));

        el.style.removeProperty("--ebz-body-scroll-x");
        el.style.removeProperty("--ebz-body-scroll-y");
        el.classList.remove("ebz-overlay-scroll-blocked");

        el.scrollLeft = -x;
        el.scrollTop = -y;
      });
      if (scrollableParent) {
        root.value!.classList.remove("ebz-overlay--scroll-blocked");
      }
    });
  },
  true
);

// Create overlay context
const overlayContext: EbzOverlayContext = {
  id: toRef(props, "id"),
  absolute: toRef(props, "absolute"),
  persistent,
  contained,
  visible: hasContent,
  active,
  open: () => (active.value = true),
  close: () => (active.value = false),
  toggle: () => (active.value = !active.value),
  clickOutside: tryClose,
};
provide(EBZ_OVERLAY_CONTEXT, overlayContext);

defineExpose(overlayContext);
</script>

<style lang="scss">
@use "sass:selector";

.ebz-overlay-container {
  contain: layout;
  top: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  display: contents;
}

.ebz-overlay-scroll-blocked {
  padding-inline-end: var(--ebz-scrollbar-offset);
  overflow-y: hidden !important;
}

.ebz-overlay {
  border-radius: inherit;
  display: flex;
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.ebz-overlay__content {
  position: absolute;
  z-index: 1;
  outline: none;
  pointer-events: auto;
  contain: layout;
}

.ebz-overlay__backdrop {
  z-index: 0;
  pointer-events: auto;
  background-color: rgba(#000, 0.5);
  border-radius: inherit;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}

.ebz-overlay--absolute {
  position: absolute;
}

.ebz-overlay--contained .ebz-overlay__backdrop {
  position: absolute;
}

.ebz-overlay--scroll-blocked {
  padding-inline-end: var(--ebz-scrollbar-offset);
}
</style>
