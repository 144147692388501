<template>
  <EbzSprite
    :src="src"
    :data="data"
    animation="TITLE_MARK"
    :scale="1.05"
    :speed="speed"
    :delay="delay"
    :play="play"
    align-y="bottom"
  >
    <slot></slot>
  </EbzSprite>
</template>

<script lang="ts" setup>
import { EbzSprite } from "@/components/EbzSprite";
import src from "@/assets/img/pg-1/title_mark.webp?format=webp&quality=85&imagetools";
import data from "@/assets/img/pg-1/title_mark.json";
import { useSpriteLoader } from "@/composables/useSpriteLoader";

export interface HeadingProcessMarkProps {
  delay?: number;
  speed?: number;
  play?: boolean;
}

withDefaults(defineProps<HeadingProcessMarkProps>(), {
  delay: 0,
  speed: 0.5,
});

await useSpriteLoader(src);
</script>
