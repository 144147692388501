<template>
  <div
    class="ebz-home-page relative w-100vw flex flex-col color-white"
    :style="{ height: `${height}px` }"
  >
    <!-- Main nav menu -->
    <EbzNavMenu
      class="absolute top-6 right-8 md:top-10 md:right-14"
      v-model="menuOpenedModel"
      ref="menuRef"
    >
      <slot name="menu"></slot>
    </EbzNavMenu>

    <!-- Brand Symbol -->
    <div class="flex flex-1 pt-8">
      <div
        class="relative flex flex-col items-center justify-center m-auto w-75% sm:w-55% md:w-45% lg:w-40% max-w-[600px] max-h-50vh"
      >
        <EbzBrandSymbol
          color="var(--ebz-c-white)"
          class="m-auto"
          width="100%"
          height="100%"
          animate
          :speed="1.25"
          :animation-delay="0.75"
          @complete="onAnimationComplete"
        ></EbzBrandSymbol>
      </div>
    </div>

    <!-- Bottom icons -->
    <div class="flex items-center gap-2 px-8 pb-10 md:px-14" ref="iconsRef">
      <template v-for="{ icon, label, url } in socialLinks" :key="label">
        <a
          v-if="!!icon"
          :aria-label="label"
          :title="label"
          :href="url"
          target="_blank"
          class="hover:translate-y-[-5px] transition-all-250"
          ><EbzIcon :name="icon" size="2rem"
        /></a>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, toRefs, computed, watch, onMounted } from "vue";
import { useSocialLinks } from "@/composables/useSocialLinks";
import { EbzIcon } from "@/components/EbzIcon";
import { useGsap } from "@/composables/useGsap";
import { useWindowSize } from "@vueuse/core";
import { EbzBrandSymbol } from "@/components/EbzBrandSymbol";
import { EbzNavMenu } from "@/components/EbzNavMenu";

export interface HomePageProps {
  menuOpened?: boolean;
}

const props = defineProps<HomePageProps>();
const propsRef = toRefs(props);
const emit = defineEmits(["update:menuOpened"]);
const menuOpened = ref(propsRef.menuOpened.value);
const gsap = useGsap();

// Use window real height to get correct height on mobile
const { height } = useWindowSize({
  includeScrollbar: false,
});

const socialLinks = useSocialLinks();

watch(
  propsRef.menuOpened,
  (opened) => {
    menuOpened.value = opened;
  },
  { immediate: true }
);

const menuOpenedModel = computed({
  get() {
    return menuOpened.value;
  },
  set(opened: boolean) {
    menuOpened.value = opened;
    emit("update:menuOpened", opened);
  },
});

const menuRef = ref<InstanceType<typeof EbzNavMenu>>();
const iconsRef = ref<HTMLElement>();

const onAnimationComplete = () => {
  const menuEl = menuRef.value!.$el!;
  const iconsEl = iconsRef.value!;

  gsap.to([menuEl, iconsEl], {
    opacity: 1,
    x: 0,
    duration: 0.75,
    ease: "circ.inOut",
    delay: 0.25,
  });
};

onMounted(() => {
  const menuEl = menuRef.value?.$el!;
  const iconsEl = iconsRef.value!;

  gsap.set(menuEl, {
    opacity: 0,
    x: 50,
  });
  gsap.set(iconsEl, {
    opacity: 0,
    x: -50,
  });
});
</script>
