<template>
  <div
    class="ebz-piece-of-paper"
    :class="{
      'ebz-piece-of-paper--loading': isImageLoading,
      'ebz-piece-of-paper--interactive': interactive,
      'ebz-piece-of-paper--active': active,
      'ebz-piece-of-paper--clickable': clickable,
      'ebz-piece-of-paper--float': float,
      'ebz-focusable': clickable,
    }"
    :role="clickable ? 'button' : undefined"
    :tabindex="clickable ? 0 : undefined"
    @click.prevent.stop="clickable && emit('click')"
    @keypress.enter.prevent.stop="clickable && emit('click')"
    ref="elementRef"
  >
    <div class="ebz-piece-of-paper__backdrop">
      <div
        class="ebz-piece-of-paper__sprite ebz-piece-of-paper__shadow"
        aria-hidden="true"
      />
    </div>

    <div class="ebz-piece-of-paper__view">
      <div
        class="ebz-piece-of-paper__sprite ebz-piece-of-paper__paper"
        aria-hidden="true"
        draggable="false"
      />

      <div class="ebz-piece-of-paper__content" :class="contentClass">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import spritesheetMetadata from "@/assets/img/piece-of-paper/metadata.json";
import { computed, ref, toRef, type PropType } from "vue";
import { useElementSize, useImage } from "@vueuse/core";
import { propsFactory } from "@/common/props";
import { useImgPreload } from "@/composables/useImgPreload";

export type EbzPieceOfPaperVariant =
  keyof typeof spritesheetMetadata.coordinates;

const props = defineProps(makeEbzPieceOfPaperProps());

const emit = defineEmits<{
  (event: "click"): void;
}>();

const variant = toRef(props, "variant");
const interactive = toRef(props, "interactive");

const { isLoading: isImageLoading } = useImage({
  src: spritesheetImage,
});

useImgPreload(spritesheetImage);

const elementRef = ref<HTMLElement>();
const { width: elementWidth } = useElementSize(elementRef);
const spritesheetRatio = spritesheetMetadata.width / spritesheetMetadata.height;

const sprite = computed(() => {
  const coords = spritesheetMetadata.coordinates[variant.value];

  const ratio = coords.width / coords.height;
  const offsetRatio = coords.offsetWidth / coords.offsetHeight;

  const width = elementWidth.value;
  const height = width / ratio;

  const backgroundWidth =
    (1 / (coords.width / spritesheetMetadata.width)) * width;
  const backgroundHeight = backgroundWidth / spritesheetRatio;
  const offsetX = (coords.offsetX / coords.width) * width;
  const offsetY = (coords.offsetY / coords.height) * height;
  const posX = (coords.x / spritesheetMetadata.width) * backgroundWidth;
  const posY = (coords.y / spritesheetMetadata.height) * backgroundHeight;
  const shadowPosX =
    (coords.shadow.x / spritesheetMetadata.width) * backgroundWidth;
  const shadowPosY =
    (coords.shadow.y / spritesheetMetadata.height) * backgroundHeight;

  return {
    background: `url(${
      isImageLoading.value ? spritesheetImageLQ : spritesheetImage
    })`,
    backgroundSize: `${backgroundWidth}px ${backgroundHeight}px`,
    backgroundPosition: `${-posX}px ${-posY}px`,
    ratio,
    offsetRatio,
    left: `calc(50% + (${offsetX}px))`,
    top: `calc(50% + (${offsetY}px))`,
    shadow: {
      backgroundPosition: `${-shadowPosX}px ${-shadowPosY}px`,
    },
  };
});
</script>

<script lang="ts">
export const makeEbzPieceOfPaperProps = propsFactory(
  {
    variant: {
      type: String as PropType<EbzPieceOfPaperVariant>,
      required: true,
    },
    contentClass: null,
    interactive: Boolean,
    clickable: Boolean,
    active: Boolean,
    float: Boolean,
  },
  "ebz-piece-of-paper"
);

export const VARIANT_NAMES = Object.keys(
  spritesheetMetadata.coordinates
) as EbzPieceOfPaperVariant[];

const spritesheetImage = Object.values(
  import.meta.glob<string>("@/assets/img/piece-of-paper/spritesheet.*", {
    eager: true,
    import: "default",
    query: {
      format: "webp",
    },
  })
)[0];

const spritesheetImageLQ = Object.values(
  import.meta.glob<string>("@/assets/img/piece-of-paper/spritesheet.*", {
    eager: true,
    import: "default",
    query: {
      width: 36,
      quality: 50,
      format: "webp",
    },
  })
)[0];
</script>

<style lang="scss" scoped>
.ebz-piece-of-paper {
  &:not(.ebz-piece-of-paper--float) {
    aspect-ratio: v-bind("sprite.offsetRatio");
  }

  &__sprite {
    background-image: v-bind("sprite.background");
    background-size: v-bind("sprite.backgroundSize");
    background-position: v-bind("sprite.backgroundPosition");
    aspect-ratio: v-bind("sprite.ratio");
    left: v-bind("sprite.left");
    top: v-bind("sprite.top");
  }

  &__shadow {
    background-position: v-bind("sprite.shadow.backgroundPosition");
  }
}
</style>

<style lang="scss">
@use "@/styles/tools";

.ebz-piece-of-paper {
  position: relative;
  display: inline-block;
  perspective: 40px;
  z-index: 0;
  contain: layout;

  &--loading {
    .ebz-piece-of-paper {
      &__sprite {
        @include tools.image-pixelated;
      }
    }
  }

  &--clickable {
    cursor: pointer;
  }

  &__view,
  &__backdrop {
    transition-property: transform;
    transition-duration: 2.25s;
    transition-timing-function: cubic-bezier(0.25, 0.3, 0, 1);
    transform-origin: center center;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    width: 100%;
    height: 100%;
    will-change: transform;
  }

  &__view {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__backdrop {
    z-index: -1;
    pointer-events: none;
  }

  &__paper,
  &__backdrop,
  &__shadow {
    position: absolute;
    backface-visibility: hidden;
    top: 0;
    left: 0;
  }

  &__sprite {
    width: 100%;
    transform: translate3d(-50%, -50%, 0);
    background-repeat: no-repeat;
    pointer-events: none;
    user-select: none;
    -webkit-user-drag: none;
    z-index: -1;
  }

  &--interactive {
    &:hover,
    &:focus-visible,
    &.ebz-piece-of-paper--active {
      z-index: 10;

      .ebz-piece-of-paper {
        &__view,
        &__backdrop {
          transition-duration: 1.75s;
          transition-timing-function: cubic-bezier(0.25, 0.3, 0, 1);
        }

        &__view {
          transform: translate3d(0, 0, 6px);
        }

        &__backdrop {
          transform: translate3d(0, 12px, 5px);
        }
      }
    }
  }
}
</style>
