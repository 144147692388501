<template>
  <div
    class="ebz-contact-form-field flex flex-col gap-2 md:flex-row md:items-start md:gap-0"
  >
    <div class="md:w-300px">
      <EbzSprite
        class="ml-2.5em md:ml-1em md:mt--0.25rem"
        v-bind="spriteProps"
        :animation="animationName"
      >
        <label class="text-h2 !mb-0" :for="fieldId">{{ label }}</label>
      </EbzSprite>
    </div>

    <div class="md:flex-grow-1">
      <slot
        v-bind="{
          id: fieldId,
          name: props.name,
          class: 'text-h6 !fw-normal md:text-h5',
        }"
      ></slot>
    </div>
  </div>
</template>

<script lang="ts">
export const makeContactFormFieldProps = propsFactory({
  id: String,
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  ...makeEbzSpriteProps({
    src: spriteSrc,
    data: spriteData,
    scale: 1.75,
    offsetY: "0.25em",
  }),
});
</script>

<script lang="ts" setup>
import spriteSrc from "@/assets/img/pg-5/popup/spritesheet.webp?format=webp&imagetools";
import spriteData from "@/assets/img/pg-5/popup/spritesheet.json";
import { EbzSprite, makeEbzSpriteProps } from "@/components/EbzSprite";
import { propsFactory } from "@/common/props";
import { computed } from "vue";
import { useSpriteLoader } from "@/composables/useSpriteLoader";

const props = defineProps(makeContactFormFieldProps());
const spriteProps = makeEbzSpriteProps.reactiveFilter(props, "animation");

await useSpriteLoader(spriteSrc);

const fieldId = computed(() => props.id || `ebz-contact-field-${props.name}`);
const animationName = computed(
  () => props.animation || `MARK_${props.name.toUpperCase()}`
);
</script>

<style lang="scss" scoped>
@use "sass:string";
@use "@/styles/tools";

.ebz-contact-form-field {
  margin: 0.75rem 0;

  &:first-child {
    margin-top: 0;
  }

  :deep(.ebz-field) {
    margin: 0;
    width: 100%;
  }
}
</style>
