<template>
  <div
    class="ebz-nav-menu"
    :class="{
      'ebz-nav-menu--active': activeModel,
    }"
  >
    <div class="ebz-nav-menu__wrapper" ref="wrapperRef">
      <Transition
        :name="activeModel ? undefined : 'ebz-transition-fade-out-right'"
        appear
      >
        <KeepAlive>
          <div
            class="ebz-nav-menu__view md:top-50% md:translate-y--50%"
            :id="menuId"
            v-if="activeModel"
          >
            <div
              class="ebz-nav-menu__items flex-col items-end gap-4 md:flex-row md:items-center md:gap-6"
              ref="itemsRef"
              role="menu"
            >
              <slot>
                <EbzNavMenuItem href="#"> Nav item </EbzNavMenuItem>
              </slot>
            </div>
          </div>
        </KeepAlive>
      </Transition>

      <slot
        name="activator"
        :model-value="activeModel"
        @update:model-value="(v: boolean) => activeModel = v"
        v-bind="{ 'aria-controls': menuId }"
      >
        <EbzNavButton
          v-model="activeModel"
          color="var(--ebz-c-white)"
          :aria-controls="menuId"
        />
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
export const makeEbzNavMenuProps = propsFactory(
  {
    modelValue: Boolean,
    id: String,
  },
  "ebz-nav-menu"
);
</script>

<script setup lang="ts">
import { computed, onScopeDispose, provide, ref, watch } from "vue";
import { useMagicKeys, useVModel } from "@vueuse/core";
import { EbzNavButton } from "@/components/EbzNavButton";
import { propsFactory } from "@/common/props";
import { useGsap } from "@/composables/useGsap";
import { EBZ_NAV_MENU, type EbzNavMenuContext } from "./key";
import { useFocusTrap } from "@vueuse/integrations/useFocusTrap";
import { useToggleScope } from "@/composables/useToggleScope";
import { getUid } from "@/common/uid";
import { EbzNavMenuItem } from "../EbzNavMenuItem";

const props = defineProps({
  ...makeEbzNavMenuProps(),
});

const emit = defineEmits(["update:modelValue"]);

const gsap = useGsap();
const activeModel = useVModel(props, "modelValue", emit);
const itemsRef = ref<HTMLElement>();
const wrapperRef = ref<HTMLElement>();
const menuId = computed(() => props.id || "ebz-nav-menu-" + getUid());

const context: EbzNavMenuContext = {
  active: activeModel,
};
provide(EBZ_NAV_MENU, context);

// Trap focus
const { activate, deactivate } = useFocusTrap(wrapperRef, {
  escapeDeactivates: false,
});

useToggleScope(
  () => activeModel.value,
  () => {
    activate();

    // Close on scroll
    const onScroll = () => {
      activeModel.value = false;
    };
    window.addEventListener("scroll", onScroll, { once: true });

    // Close menu on esc
    const { escape } = useMagicKeys();
    watch(
      () => escape.value,
      (pressed) => {
        if (pressed && activeModel.value) {
          activeModel.value = false;
        }
      }
    );

    onScopeDispose(() => {
      deactivate();
      window.removeEventListener("scroll", onScroll);
    });
  }
);

let tween: gsap.core.Tween | null = null;
let timeout: NodeJS.Timeout | null = null;
watch(
  () => itemsRef.value,
  (itemsEl) => {
    tween?.kill();
    tween = null;

    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }

    if (itemsEl) {
      gsap.set(itemsEl, {
        opacity: 0,
      });

      timeout = setTimeout(() => {
        const childrenEl = itemsEl.querySelectorAll(".ebz-nav-menu-item");

        gsap.set(childrenEl, {
          opacity: 0,
          xPercent: 25,
        });
        gsap.set(itemsEl, {
          opacity: 1,
        });

        tween = gsap.to(childrenEl, {
          duration: 0.5,
          opacity: 1,
          xPercent: 0,
          stagger: -0.05,
          ease: "power3.out",
        });

        timeout = null;
      }, 50);
    }
  }
);
</script>

<style lang="scss">
:root {
  --ebz-nav-menu-bg: var(--ebz-c-white);
  --ebz-nav-menu-transition-duration: 0.4s;
}

.ebz-nav-menu {
  align-items: center;
  justify-content: center;
  margin: auto;

  &__wrapper {
    position: relative;
  }

  &__view {
    position: absolute;
    right: 100%;
    margin-right: 1.5rem;
  }

  &__items {
    flex: 1 1 auto;
    display: flex;
  }

  &-transition {
    &-enter-active,
    &-leave-active {
      transition: all var(--ebz-nav-menu-transition-duration)
        cubic-bezier(0.16, 1, 0.3, 1);
      overflow: hidden !important;
    }

    &-leave-from,
    &-leave-to,
    &-leave-active {
      pointer-events: none !important;
    }

    &-leave-to,
    &-enter-from {
      opacity: 0;
    }

    &-leave-from,
    &-enter-to {
      opacity: 1;
    }
  }
}
</style>
