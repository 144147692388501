<template>
  <section
    class="ebz-process-page color-white relative w-100vw min-h-100vh mb-10 overflow-hidden flex items-center justify-center lt-lg:flex-col lt-lg:gap-7rem lt-lg:py-4rem"
    ref="elementRef"
  >
    <!-- Note -->
    <div
      class="flex items-center justify-center w-100% md:absolute md:bottom-10% md:left-10% md:w-auto"
      v-memo="[shouldAnimate]"
    >
      <div class="flex flex-col gap-2">
        <div class="ebz-process-page__note-title opacity-0">
          <EbzSprite
            :src="titleMarkSrc"
            :data="titleMarkData"
            :scale="1.4"
            :delay="0.5"
            :play="shouldAnimate"
            offset-y="-0.5em"
            offset-x="0.25em"
            animation="MARK"
          >
            <h3>{{ t("process_page.note.title") }}</h3>
          </EbzSprite>
        </div>

        <div class="ebz-process-page__note-text opacity-0 whitespace-pre-line">
          <EbzSprite
            :src="frameSrc"
            :data="frameData"
            :scale="1.25"
            :delay="1"
            :play="shouldAnimate"
            align-x="left"
            align-y="bottom"
            offset-x="-2em"
            offset-y="-1.25em"
            animation="FRAME"
          >
            <p class="text-h6 mb-2">{{ t("process_page.note.text") }}</p>
          </EbzSprite>
        </div>
      </div>
    </div>

    <!-- Brain -->
    <div class="ebz-process-page__brain relative fw-500 mr-5% md:mr-0" v-once>
      <Brain
        class="w-100% pointer-events-none md:w-60vw md:max-h-auto"
        ref="brainRef"
      ></Brain>

      <!-- eBrainz -->
      <div
        class="absolute top-40% left-50% translate-x-[-50%] translate-y-[-60%] md:top-42% md:left-48% md:translate-x-[-52%] md:translate-y-[-58%]"
      >
        <EbzPieceOfPaper variant="c">
          <div class="px-2.75em">
            <EbzBrandTypography size="2em" color="white"></EbzBrandTypography>
          </div>
        </EbzPieceOfPaper>
      </div>

      <!------------------------------->
      <!--            RIGHT          -->
      <!------------------------------->
      <!-- Step 1 -->
      <div
        class="absolute top-77% left-68% translate-x-[-32%] translate-y-[-23%] rotate-[-20deg] md:top-48% md:left-76% md:translate-x-[-24%] md:translate-y-[-52%] md:rotate-[48deg]"
      >
        <EbzPieceOfPaper
          class="ebz-process-page__step"
          id="ebz-process-step-1"
          variant="c"
          v-bind="getActivatorProps('execution', 1)"
        >
          <div class="px-1.75em">{{ t("process_page.steps.1") }}</div>
        </EbzPieceOfPaper>
      </div>

      <!-- Step 2 -->
      <div
        class="absolute top-88% left-84% translate-x-[-16%] translate-y-[-12%] rotate-[-20deg] md:top-11% md:left-79% md:translate-x-[-21%] md:translate-y-[-89%] md:rotate-0"
      >
        <EbzPieceOfPaper
          class="ebz-process-page__step"
          id="ebz-process-step-2"
          variant="a"
          v-bind="getActivatorProps('execution', 2)"
        >
          <div class="px-1.75em whitespace-nowrap">
            {{ t("process_page.steps.2") }}
          </div>
        </EbzPieceOfPaper>
      </div>

      <!-- Step 3 -->
      <div
        class="absolute top-101% left-64% translate-x-[-36%] translate-y-[-1%] rotate-[6deg] md:top-38% md:left-101% md:translate-x-[1%] md:translate-y-[-62%] md:rotate-[-3deg]"
      >
        <EbzPieceOfPaper
          class="ebz-process-page__step"
          id="ebz-process-step-3"
          variant="b"
          v-bind="getActivatorProps('execution', 3)"
        >
          <div class="px-1.75em">{{ t("process_page.steps.3") }}</div>
        </EbzPieceOfPaper>
      </div>

      <!------------------------------->
      <!--           BOTTOM          -->
      <!------------------------------->
      <!-- Step 4 -->
      <div
        class="absolute top-[-15%] left-50% translate-x-[-50%] translate-y-[15%] md:top-102% md:translate-y-[2%]"
      >
        <EbzPieceOfPaper
          class="ebz-process-page__step"
          id="ebz-process-step-4"
          variant="e"
          v-bind="getActivatorProps('projects', 4)"
        >
          <div class="px-2.5em mt-[-0.25em] text-[0.85em]">
            {{ t("process_page.steps.4") }}
          </div>
        </EbzPieceOfPaper>
      </div>

      <!------------------------------->
      <!--            LEFT           -->
      <!------------------------------->
      <!-- Step 5 -->
      <div
        class="absolute top-76% left-28% translate-x-[-72%] translate-y-[-24%] rotate-[4deg] md:top-24% md:left-25% md:translate-x-[-75%] md:translate-y-[-76%] md:rotate-[-45deg]"
      >
        <EbzPieceOfPaper
          class="ebz-process-page__step"
          id="ebz-process-step-5"
          variant="e"
          v-bind="getActivatorProps('pixel', 5)"
        >
          <div class="px-2.5em mt-[-0.25em] text-[0.85em]">
            {{ t("process_page.steps.5") }}
          </div>
        </EbzPieceOfPaper>
      </div>

      <!-- Step 6 -->
      <div
        class="absolute top-99% left-26% translate-x-[-74%] translate-y-[-1%] rotate-[6deg] md:top-8% md:left-8% md:translate-x-[-92%] md:translate-y-[-92%] md:rotate-[-5deg]"
      >
        <EbzPieceOfPaper
          class="ebz-process-page__step"
          id="ebz-process-step-6"
          variant="c"
          v-bind="getActivatorProps('pixel', 6)"
        >
          <div class="px-2.75em text-[1.25em] fw-black">
            {{ t("process_page.steps.6") }}
          </div>
        </EbzPieceOfPaper>
      </div>
    </div>

    <!-- Popup -->
    <ProcessPopup
      id="process-popup"
      v-model="popupOpened"
      @after-leave="currentPopupName = null"
    >
      <Suspense>
        <component
          :is="popupComponents[currentPopupName!]"
          :delay="0.5"
        ></component>

        <template #fallback>
          <div class="w-full h-full flex items-center justify-center">
            <EbzLoading size="6rem"></EbzLoading>
          </div>
        </template>
      </Suspense>
    </ProcessPopup>
  </section>
</template>

<script lang="ts" setup>
import {
  type Component,
  computed,
  defineAsyncComponent,
  nextTick,
  onMounted,
  ref,
  watch,
} from "vue";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { EbzBrandTypography } from "@/components/EbzBrandTypography";
import { EbzPieceOfPaper } from "@/components/EbzPieceOfPaper";
import titleMarkSrc from "@/assets/img/pg-3/mark.webp?format=webp&quality=85&imagetools";
import titleMarkData from "@/assets/img/pg-3/mark.json";
import frameSrc from "@/assets/img/pg-3/frame.webp?format=webp&quality=85&imagetools";
import frameData from "@/assets/img/pg-3/frame.json";
import Brain from "./Brain.vue";
import { EbzSprite } from "@/components/EbzSprite";
import { useI18n } from "vue-i18n";
import { useGsap } from "@/composables/useGsap";
import { kebabCase } from "@/common/cases";
import { EbzLoading } from "@/components/EbzLoading";

const ProcessPopup = defineAsyncComponent(() => import("./ProcessPopup.vue"));

const { t } = useI18n();

const gsap = useGsap();
gsap.registerPlugin(ScrollTrigger);

const elementRef = ref<HTMLElement | null>(null);
const brainRef = ref<InstanceType<typeof Brain> | null>(null);
const isLoading = computed(() => brainRef.value?.isLoading ?? true);
const entered = ref(false);
const shouldAnimate = computed(() => !isLoading.value && entered.value);
const popupOpened = ref(false);

const popupComponents = Object.fromEntries(
  Object.entries(import.meta.glob<Component>("./popups/*.vue")).map(
    ([path, componentFactory]) => {
      const componentName = path
        .replace("./popups/", "")
        .replace(/(Popup)?\.vue/, "");

      return [
        kebabCase(componentName),
        defineAsyncComponent({
          loader: componentFactory,
        }),
      ];
    }
  )
);

const currentPopupName = ref<string | null>(null);

const openPopup = (name: string) => {
  if (name in popupComponents) {
    currentPopupName.value = name;
    popupOpened.value = true;
  } else {
    console.warn(`Could not find popup: ${name}`);

    if (currentPopupName.value) {
      popupOpened.value = false;
    }
  }
};

const getActivatorProps = (name: string, key: string | number) => ({
  "aria-haspopup": "dialog",
  "aria-controls": "process-popup",
  "aria-expanded": popupOpened.value && currentPopupName.value === name,
  "aria-label": t("process_page.a11y.activator", {
    label: t(`process_page.steps.${key}`),
    name: t(`process_page.a11y.popup_names.${name}`),
  }),
  interactive: true,
  clickable: true,
  onClick: () => openPopup(name),
});

function animate() {
  const brainEl = brainRef.value!.$el as HTMLElement;
  const svg = brainEl.querySelector("svg")!;
  const leftArrow = svg.querySelector(".ebz-brain__left-arrow")!;
  const rightArrow = svg.querySelector(".ebz-brain__right-arrow")!;
  const bottomArrow = svg.querySelector(".ebz-brain__bottom-arrow")!;
  const leftSide = svg.querySelector(".ebz-brain__left-side")!;
  const rightSide = svg.querySelector(".ebz-brain__right-side")!;

  gsap.set([leftArrow, rightArrow, bottomArrow, leftSide, rightSide], {
    strokeDasharray: 110,
    strokeDashoffset: 110,
  });

  gsap.set(".ebz-process-page__step", {
    opacity: 0,
    scale: 1.25,
  });

  gsap.set(".ebz-process-page__note-title", {
    opacity: 0,
    xPercent: 20,
  });

  gsap.set(".ebz-process-page__note-text", {
    opacity: 0,
    xPercent: -20,
  });

  const stepIn: gsap.TweenVars = {
    opacity: 1,
    scale: 1,
    duration: 0.25,
  };

  gsap
    .timeline({
      defaults: {
        ease: "power1.inOut",
        duration: 0.75,
      },
    })
    .to(".ebz-process-page__note-title", {
      opacity: 1,
      xPercent: 0,
      ease: "power2.out",
    })
    .to(".ebz-process-page__note-text", {
      opacity: 1,
      xPercent: 0,
      ease: "power2.out",
    })
    .to(
      ["#ebz-process-step-1", "#ebz-process-step-2", "#ebz-process-step-3"],
      stepIn,
      1.5
    )
    .to(["#ebz-process-step-5", "#ebz-process-step-6"], stepIn, 2.25)
    .to("#ebz-process-step-4", stepIn, 3)
    .to(
      leftSide,
      {
        strokeDashoffset: 0,
      },
      1
    )
    .to(
      rightSide,
      {
        strokeDashoffset: 0,
      },
      1.5
    )
    .to(
      rightArrow,
      {
        strokeDashoffset: 0,
      },
      2
    )
    .to(
      leftArrow,
      {
        strokeDashoffset: 0,
      },
      2.5
    )
    .to(
      bottomArrow,
      {
        strokeDashoffset: 0,
      },
      3
    );
}

const stop = watch(shouldAnimate, (shouldAnimate) => {
  if (!shouldAnimate) return;

  nextTick(() => stop());
  animate();
});

onMounted(() => {
  gsap.timeline({
    scrollTrigger: {
      trigger: elementRef.value!,
      end: "top 40%",
      once: true,
      onKill() {
        entered.value = true;
      },
    },
  });
});
</script>

<style lang="scss" scoped>
@use "sass:string";
@use "@/styles/tools";

.ebz-process-page {
  &__brain {
    @include tools.fluid-size(
      font-size,
      0.75rem,
      string.unquote("1.5vw"),
      1.25rem,
      true
    );
    line-height: 1.2;
  }

  &__step {
    white-space: pre-line;
    text-align: center;
    opacity: 0;
    will-change: transform, opacity;
  }

  &__note {
    &-title {
      will-change: transform, opacity;

      h3 {
        @include tools.fluid-size(
          font-size,
          1.25rem,
          string.unquote("2.5vw"),
          2rem,
          true
        );
      }
    }

    &-text {
      will-change: transform, opacity;

      h6 {
        @include tools.fluid-size(
          font-size,
          1rem,
          string.unquote("2vw"),
          1.25rem,
          true
        );
      }
    }
  }
}
</style>

<i18n lang="yaml">
pt-BR:
  process_page:
    a11y:
      activator: "{label}. Abrir popup: {name}"
      popup_names:
        video: "Vídeo eBrainz"
        execution: "Execução"
        projects: "Projetos proprietários"
        pixel: "Metodologia Pixel"
    steps:
      0: eBrainz
      1: EXECUÇÃO
      2: ON DEMAND
      3: eHUB
      4: |
        PROJETOS
        PROPRIETÁRIOS
      5: |
        PLAN, CRIAÇÃO
        E DESIGN
      6: PIXEL
    note:
      title: OPEN WORLD
      text: |
        Nossa empresa
        tem um formato
        mundo aberto.
</i18n>
