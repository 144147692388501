<template>
  <span
    :class="[
      'ebz-sprite',
      {
        'ebz-sprite--loading': loading,
        'ebz-sprite--playing': playing,
      },
    ]"
    ref="elementRef"
  >
    <canvas
      ref="canvasRef"
      role="presentation"
      aria-hidden="true"
      :style="{
        left: alignment.left,
        top: alignment.top,
        right: alignment.right,
        bottom: alignment.bottom,
        transform: alignment.transform,
      }"
    ></canvas>

    <slot></slot>
  </span>
</template>

<script lang="ts">
export const makeEbzSpriteProps = propsFactory(
  {
    src: {
      type: String,
      required: true,
    },
    data: {
      type: Object as PropType<SpriteSheetJson>,
      required: true,
    },
    frame: String,
    animation: String,
    play: {
      type: Boolean,
      default: true,
    },
    loop: Boolean,
    speed: {
      type: [Number, String],
      default: 0.7,
      validator: (value: any) => !isNaN(parseFloat(value)),
    },
    delay: {
      type: [Number, String],
      default: 0,
      validator: (value: any) => !isNaN(parseFloat(value)),
    },
    restartOnPlay: Boolean,
    scale: {
      type: [Number, String],
      default: 1,
      validator: (value: any) => !isNaN(parseFloat(value)),
    },
    alignX: {
      type: String,
      default: "center",
    },
    alignY: {
      type: String,
      default: "center",
    },
    offsetX: [String, Number],
    offsetY: [String, Number],
    async: Boolean,
  },
  "ebz-sprite"
);
</script>

<script lang="ts" setup>
import { coercePixel } from "@/common/coercion";
import { propsFactory } from "@/common/props";
import { useGsap } from "@/composables/useGsap";
import { computedAsync, useElementSize, useVModel } from "@vueuse/core";
import { Application } from "@pixi/app";
import { UPDATE_PRIORITY, utils } from "@pixi/core";
import { AnimatedSprite } from "@pixi/sprite-animated";
import { Sprite } from "@pixi/sprite";
import { Spritesheet, type SpriteSheetJson } from "@pixi/spritesheet";
import {
  computed,
  onMounted,
  onUnmounted,
  ref,
  shallowRef,
  toRef,
  watch,
  type PropType,
} from "vue";
import { loadTexture } from "@/common/pixi";

const props = defineProps(makeEbzSpriteProps());

const emit = defineEmits(["afterLoop", "afterComplete", "update:play"]);

const gsap = useGsap();

const srcRef = toRef(props, "src");
const dataRef = toRef(props, "data");
const animationRef = toRef(props, "animation");
const loopRef = toRef(props, "loop");
const speedRef = toRef(props, "speed");
const restartOnPlayRef = toRef(props, "restartOnPlay");
const delayRef = toRef(props, "delay");
const scaleRef = toRef(props, "scale");
const alignXRef = toRef(props, "alignX");
const alignYRef = toRef(props, "alignY");
const offsetXRef = toRef(props, "offsetX");
const offsetYRef = toRef(props, "offsetY");
const frameRef = toRef(props, "frame");
const playRef = useVModel(props, "play", emit);

const elementRef = ref<HTMLDivElement>();
const canvasRef = ref<HTMLCanvasElement>();
const appRef = shallowRef<Application>();
const spriteRef = shallowRef<Sprite>();
const loading = ref(false);
const playing = ref(false);
const activeFrame = ref<string>();

const { width: elWidth, height: elHeight } = useElementSize(elementRef);
const width = computed(() => elWidth.value * Number(scaleRef.value));
const height = computed(() => elHeight.value * Number(scaleRef.value));

const alignment = computed(() => {
  const alignX = alignXRef.value;
  const alignY = alignYRef.value;
  const offsetX = offsetXRef.value ? coercePixel(offsetXRef.value) : 0;
  const offsetY = offsetYRef.value ? coercePixel(offsetYRef.value) : 0;
  const left = alignX === "left" ? "0%" : alignX === "right" ? "auto" : "50%";
  const top = alignY === "top" ? "0%" : alignY === "bottom" ? "auto" : "50%";
  const right = alignX === "right" ? "0%" : "auto";
  const bottom = alignY === "bottom" ? "0%" : "auto";

  return {
    left: left !== "auto" && offsetX ? `calc(${left} + ${offsetX})` : left,
    top: top !== "auto" && offsetY ? `calc(${top} + ${offsetY})` : top,
    right: right !== "auto" && offsetX ? `calc(${right} + ${offsetX})` : right,
    bottom:
      bottom !== "auto" && offsetY ? `calc(${bottom} + ${offsetY})` : bottom,
    transform: `translate3d(${left === "50%" ? "-50%" : "0"}, ${
      top === "50%" ? "-50%" : "0"
    }, 0)`,
  };
});

const spritesheetRef = computedAsync(
  async () => {
    const src = srcRef.value;
    const data = dataRef.value;

    const texture = await loadTexture(src);

    // Avoid PixiJS warnings about duplicate cache keys, as the keys inside a spritesheet
    // can conflict with the keys of other spritesheets
    utils.clearTextureCache();

    const spritesheet = new Spritesheet(texture, data);
    await spritesheet.parse();

    return spritesheet;
  },
  null,
  {
    evaluating: loading,
    shallow: true,
  }
);

const sizeRef = computed(() => {
  const data = dataRef.value;
  const animation = animationRef.value;

  const frameName = animation
    ? data.animations?.[animation]?.[0]
    : frameRef.value;

  if (frameName) {
    const frameData = data.frames[frameName];

    if (frameData) {
      const orig =
        frameData.trimmed !== false && frameData.sourceSize
          ? frameData.sourceSize
          : frameData.frame;
      const ratio = orig.w / orig.h;
      const elRatio = width.value / height.value;

      if (elRatio > ratio) {
        const h = Math.min(height.value, orig.h);
        return {
          w: h * ratio,
          h,
        };
      } else {
        const w = Math.min(width.value, orig.w);
        return {
          w,
          h: w / ratio,
        };
      }
    }
  }
  return null;
});

let resizeId: number | null = null;

const cancelResize = () => {
  if (resizeId !== null) {
    cancelAnimationFrame(resizeId);
    resizeId = null;
  }
};

const queueResize = (w: number, h: number) => {
  const app = appRef.value;
  const sprite = spriteRef.value;

  cancelResize();

  if (!app || !sprite) return;

  resizeId = requestAnimationFrame(() => {
    cancelResize();

    if (!app || !sprite) return;

    app.renderer.resize(w, h);
    sprite.anchor.set(0.5);
    sprite.width = app.screen.width;
    sprite.height = app.screen.height;
    sprite.x = app.screen.width / 2;
    sprite.y = app.screen.height / 2;
    app.render();

    resizeId = null;
  });
};

watch(
  [appRef, spriteRef, sizeRef],
  ([app, sprite, size]) => {
    if (!app || !sprite || !size) return;
    queueResize(size.w, size.h);
  },
  { immediate: true, flush: "sync" }
);

watch(frameRef, (frame) => {
  const size = sizeRef.value;
  if (!size || frame === activeFrame.value) return;
  queueResize(size.w, size.h);
});

watch(
  [appRef, spritesheetRef, animationRef],
  ([app, spritesheet, animation]) => {
    if (!app) return;

    const activeSprite = spriteRef.value;

    if (
      activeSprite &&
      (!spritesheet || animation || activeSprite instanceof AnimatedSprite)
    ) {
      app.stage.removeChild(activeSprite);
      activeSprite.destroy();
    }

    if (!spritesheet) {
      if (activeSprite) spriteRef.value = undefined;
      return;
    }

    if (animation) {
      const frames = spritesheet.animations[animation] ?? [];

      if (!frames.length) {
        if (activeSprite) spriteRef.value = undefined;
        return;
      }

      const animatedSprite = new AnimatedSprite(frames, false);
      animatedSprite.animationSpeed = Number(speedRef.value);
      animatedSprite.loop = loopRef.value;

      animatedSprite.onComplete = () => {
        playing.value = false;
        playRef.value = false;
        emit("afterComplete");
      };
      animatedSprite.onLoop = () => {
        emit("afterLoop");
      };

      app.stage.addChild(animatedSprite);
      spriteRef.value = animatedSprite;
    } else {
      const frame = frameRef.value;
      const texture = frame ? spritesheet.textures[frame] : undefined;
      const sprite = new Sprite(texture);

      app.stage.addChild(sprite);
      spriteRef.value = sprite;
      activeFrame.value = undefined;
    }
  }
);

watch([spriteRef, speedRef, loopRef], ([sprite, speed, loop]) => {
  if (!sprite || !(sprite instanceof AnimatedSprite)) return;
  sprite.animationSpeed = Number(speed);
  sprite.loop = loop;
});

watch([appRef, spriteRef, frameRef], ([app, sprite, frame]) => {
  if (!app || !sprite || activeFrame.value === frame) return;
  const spritesheet = spritesheetRef.value!;

  if (sprite instanceof AnimatedSprite) {
    if (!frame) {
      sprite.currentFrame = 0;
      return;
    }

    const animation = animationRef.value;

    if (!animation) {
      return;
    }

    const frameNames = dataRef.value.animations?.[animation];

    if (!frameNames) {
      return;
    }

    const frameIndex = frameNames.indexOf(frame);
    if (frameIndex !== -1) {
      sprite.currentFrame = frameIndex;
    }
  } else if (frame) {
    const texture = spritesheet.textures[frame];

    if (texture) {
      sprite.texture = texture;
    }
  }

  app.render();
});

let delayTween: gsap.core.Tween | null = null;
watch([appRef, spriteRef, playRef], ([app, sprite, play]) => {
  if (delayTween !== null) {
    delayTween.kill();
    delayTween = null;
  }

  if (!app) return;

  if (!sprite || !play || !(sprite instanceof AnimatedSprite)) {
    if (sprite instanceof AnimatedSprite) {
      sprite.stop();
    }
    app.ticker.stop();
    return;
  }

  if (restartOnPlayRef.value) {
    sprite.currentFrame = 0;
  }

  if (delayRef.value) {
    delayTween = gsap.delayedCall(Number(delayRef.value), () => {
      sprite.play();
      app.ticker.start();
      playing.value = true;
    });
  } else {
    sprite.play();
    app.ticker.start();
    playing.value = true;
  }
});

onMounted(() => {
  const canvas = canvasRef.value!;

  appRef.value = new Application({
    view: canvas,
    width: width.value,
    height: height.value,
    autoStart: false,
    sharedTicker: false,
    backgroundAlpha: 0,
    antialias: false,
    resolution: 1,

    // Important because the number of WebGL contexts is limited
    forceCanvas: true,
  });

  appRef.value.ticker.add((deltaTime) => {
    const sprite = spriteRef.value;

    if (!(sprite instanceof AnimatedSprite) || !playRef.value) {
      appRef.value!.ticker.stop();
      return;
    }

    sprite.update(deltaTime);
  }, UPDATE_PRIORITY.HIGH);
});

onUnmounted(() => {
  cancelResize();
  appRef.value?.destroy(true);
});
</script>

<style lang="scss">
.ebz-sprite {
  position: relative;
  display: inline-block;
  z-index: 0;

  > canvas {
    position: absolute;
    z-index: -1;
    pointer-events: none;
  }
}
</style>
