<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="`${viewbox.minX} ${viewbox.minY} ${viewbox.width} ${viewbox.height}`"
    :width="width"
    :height="height"
    :style="{
      fontSize: size,
    }"
  >
    <path
      v-bind="pathAttrs"
      :style="{
        fill: color,
      }"
      fill-rule="evenodd"
      d="M16.281 1.278h-2.289v1.2h1.121v.986L12.281 4.66V8h2.677l.17-.254.17.254h1.017V1.278zM13.447 6.8V5.45l1.666-.686V6.8h-1.666zm3.413-5.522h1.202V8H16.86zm3.104 0l-.17.254-.17-.254h-1.017V8h1.202V2.478h1.63V8h1.202V1.278zm7.036 0h-3.815v1.2h2.382L23.185 6.8V8H27V6.8h-2.367L27 2.478zM4.58 0v8h1.08l.18-.27.18.27h2.846V4.354L7.37 3.728l1.495-.633V0zm1.276 6.797v-2.32l1.733.73v1.59zM7.59 2.259l-1.733.729V1.275H7.59zM0 1.278V8h3.95V6.8H1.201v-.309l2.832-1.196V1.278zm2.833 3.226l-1.631.687V2.478h1.63zm10.614-2.18V1.278h-2.678l-.17.25-.169-.25H9.413v1.046h-.001V8h1.202V2.474h1.667v.782h1.168v-.932h-.002z"
    />
  </svg>
</template>

<script setup lang="ts">
export interface BrandSymbolProps {
  color?: string;
  size?: string | number;
  width?: string | number;
  height?: string | number;
  pathAttrs?: Record<string, any>;
}

withDefaults(defineProps<BrandSymbolProps>(), {
  width: `${ratio}em`,
  height: `1em`,
  size: "2rem",
});
defineExpose({ viewbox });
</script>

<script lang="ts">
const viewbox = {
  minX: 0,
  minY: 0,
  width: 27,
  height: 8,
};
export const ratio = viewbox.width / viewbox.height;
</script>
