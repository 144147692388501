<template>
  <div class="ebz-ripped-paper">
    <EbzImage
      v-if="image"
      class="w-100vw pointer-events-none"
      :src="image.src"
      :srcset="image.srcset"
      :loading-src="image.srcLQ"
      :metadata="image.metadata"
      :style="{
        transform: `scaleY(${end ? -1 : 1}) translateY(${end ? -1 : 1}px)`,
      }"
      fit="cover"
      fit-position="bottom"
      repeat="x"
      repeat-flip="x"
      preload
    />

    <div
      v-if="background"
      class="w-100% h-100%"
      :style="{ background: background }"
    ></div>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { EbzImage } from "../EbzImage";

export interface EbzRippedPaperProps {
  end?: boolean;
  variant?: string;
  background?: string;
}

const props = withDefaults(defineProps<EbzRippedPaperProps>(), {
  end: false,
  variant: "a",
});
const variant = toRef(props, "variant");
const image = computed(() => images[variant.value]);
</script>

<script lang="ts">
import type { Metadata } from "sharp";
import { computed, toRef } from "vue";

const srcs = import.meta.glob<string>("@/assets/img/ripped-paper/[a-z].*", {
  import: "default",
  eager: true,
  query: {
    trim: "",
    format: "webp",
    width: "1920",
    withoutEnlargement: true,
  },
});
const srcsLQ = import.meta.glob<string>("@/assets/img/ripped-paper/[a-z].*", {
  import: "default",
  eager: true,
  query: {
    trim: "",
    width: "100",
    format: "webp",
  },
});
const srcsets = import.meta.glob<string>("@/assets/img/ripped-paper/[a-z].*", {
  import: "default",
  eager: true,
  query: {
    trim: "",
    width: "600;960;1280;1920",
    format: "webp",
    srcset: true,
    withoutEnlargement: true,
  },
});
const metadatas = import.meta.glob<Metadata>(
  "@/assets/img/ripped-paper/[a-z].*",
  {
    import: "default",
    eager: true,
    query: {
      trim: "",
      width: "1920",
      metadata: "width;height",
    },
  }
);

const images = Object.fromEntries(
  Object.entries(metadatas).map(([filepath, metadata]) => {
    const src = srcs[filepath];
    const srcLQ = srcsLQ[filepath];
    const srcset = srcsets[filepath];
    const name = filepath.slice(
      filepath.lastIndexOf("/") + 1,
      filepath.lastIndexOf(".")
    );

    return [
      name,
      {
        src,
        srcLQ,
        srcset,
        metadata,
      },
    ];
  })
);

export const VARIANT_NAMES = Object.keys(images);
</script>
