import { propsFactory } from "@/common/props";
import {
  h,
  mergeProps,
  Transition,
  type Component,
  type FunctionalComponent,
  type PropType,
  type TransitionProps,
} from "vue";

export const makeEbzTransitionProps = propsFactory(
  {
    transition: {
      type: [Boolean, String, Object] as PropType<
        string | boolean | (TransitionProps & { component?: Component })
      >,
      default: "ebz-transition-fade",
      validator: (val) => val !== true,
    },
  },
  "transition"
);

export interface EbzTransitionProps extends TransitionProps {
  transition?: string | boolean | (TransitionProps & { component?: any });
}

export const EbzTransition: FunctionalComponent<EbzTransitionProps> = (
  props,
  { slots }
) => {
  const { transition, ...rest } = props;

  const { component = Transition, ...customProps } =
    typeof transition === "object" ? transition : {};

  return h(
    component,
    mergeProps(
      typeof transition === "string"
        ? { name: transition }
        : (customProps as any),
      rest as any
    ),
    slots
  );
};
